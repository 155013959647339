import { useEffect, useState } from 'react';
import styles from './order-form.module.scss';
import {
  Accordion,
  AccordionItem,
  BreadcrumbItem,
  BreadcrumbsBar,
  Wizard
} from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import { WizardStep } from '../../models/navigation';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { ordersActions } from '../../store/orders/orders.reducer';
import PatientFilesForm from './patient-files-form/PatientFilesForm';
import MouthForm from './mouth-form/MouthForm';
import ProsthesisForm from './prosthesis-form/ProsthesisForm';
import FinalisationForm from './finalization-form/FinalisationForm';
import { ToastType } from '../../enum/feedback';
import { mapActions } from '../../store/map/map.reducer';
import { Summary } from './summary/Summary';
import {
  isLoadingFilesSelector,
  orderFile3dToDisplaySelector,
  orderFileImageToDisplaySelector,
  orderFilesSelector,
  orderFileTextureToDisplaySelector
} from '../../store/orders/orders.selectors';
import { Order, OrderFile } from '../../models/order';
import { displayFileInViewer, loadOrderFilesData } from '../file-manager/file.utils';
import { FileManagerOrientationProp } from '../file-manager/file-manager.enum';
import FileManagerContainer from '../file-manager/FileManagerContainer';
import { useLazyGetOneOrderQuery } from '../../services/orders-api.services';
import {
  useDownloadFileFromStorageMutation,
  useLazyGetOneDownloadableFileQuery
} from '../../services/files-api.services';

type props = {
  initialOrderInfo?: Order;
  isOrderLoading?: boolean;
};

const OrderForm = ({ initialOrderInfo, isOrderLoading = false }: props) => {
  const isEditMode = !!initialOrderInfo;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['order']);
  const [selectedStep, setSelectedStep] = useState<number>(0);
  const [defaultIndexAccordion, setDefaultIndexAccordion] = useState<Array<number>>();

  const [getOneOrder, { data: orderData }] = useLazyGetOneOrderQuery();
  const [getOneDownloadableFile] = useLazyGetOneDownloadableFileQuery();
  const [downloadFromStorage] = useDownloadFileFromStorageMutation();

  const orderFiles = useAppSelector(orderFilesSelector);
  const file3dToDisplay = useAppSelector(orderFile3dToDisplaySelector);
  const fileTextureToDisplay = useAppSelector(orderFileTextureToDisplaySelector);
  const fileImageToDisplay = useAppSelector(orderFileImageToDisplaySelector);
  const isLoadingFiles = useAppSelector(isLoadingFilesSelector);
  const steps: WizardStep[] = [
    { id: 0, title: t('createOrder.wizard.patient-files') },
    { id: 1, title: t('createOrder.wizard.mouth') },
    { id: 2, title: t('createOrder.wizard.prosthesis') },
    { id: 3, title: t('createOrder.wizard.finalization') }
  ];

  useEffect(() => {
    // Mandatory if we don't want to have data of the previous order visited
    if (initialOrderInfo?.orderNumber) {
      getOneOrder(initialOrderInfo?.orderNumber);
    } else {
      dispatch(ordersActions.resetOrder());
    }
  }, []);

  useEffect(() => {
    if (orderData) {
      if (orderData.orderNumber !== initialOrderInfo?.orderNumber) {
        dispatch(ordersActions.resetOrder());
      }
      if (
        (!orderFiles?.length || orderData.orderNumber !== initialOrderInfo?.orderNumber) &&
        orderData.patient?.diagnostic?.patientFiles &&
        orderData?.orderNumber
      ) {
        loadOrderFilesData(
          dispatch,
          getOneDownloadableFile,
          downloadFromStorage,
          orderData.orderNumber,
          orderData.patient.diagnostic.patientFiles
        );
      }
    }
  }, [orderData]);

  useEffect(() => {
    // Display products summary accordion by default for step 3 and step 4
    setDefaultIndexAccordion([2, 3].includes(selectedStep) ? [1] : [0]);
  }, [selectedStep]);

  const handlePrevious = () => {
    setSelectedStep(selectedStep - 1);
  };

  const handleSubmit = (orderNumber: string) => {
    dispatch(ordersActions.resetOrder());
    dispatch(mapActions.resetMap());
    dispatch(
      feedbackActions.setToast({
        message: t(isEditMode ? 'editOrder.toast.success' : 'createOrder.toast.success', {
          orderNumber
        }),
        type: ToastType.SUCCESS
      })
    );
    navigate('/dashboard');
  };

  const navigateNextStep = () => {
    setSelectedStep(selectedStep + 1);
  };

  return (
    <div className={styles['order-form']}>
      <div className={styles['order-form__header']}>
        <BreadcrumbsBar type={'navigation'}>
          <BreadcrumbItem text={t('link.dashboard', { ns: 'common' })} link="/dashboard" />
          <BreadcrumbItem
            text={
              isEditMode
                ? t('editOrder.title', {
                    orderNumber: initialOrderInfo.orderNumber
                  })
                : t('createOrder.title')
            }
            link="order/create"
          />
        </BreadcrumbsBar>
      </div>
      <div className={styles['order-form__body']}>
        <div
          className={
            styles[`order-form__body__main--${selectedStep === 0 ? 'width-full' : 'width-partial'}`]
          }>
          <div className={styles['order-form__body__main__content']}>
            <Wizard options={steps} selectedIndex={selectedStep} displayedInRow={true} />
            {!isOrderLoading && selectedStep === 0 && (
              <PatientFilesForm
                nextCallback={navigateNextStep}
                initialOrderInfo={initialOrderInfo}
              />
            )}
            {!isOrderLoading && selectedStep === 1 && (
              <MouthForm nextCallback={navigateNextStep} previousCallback={handlePrevious} />
            )}
            {!isOrderLoading && selectedStep === 2 && (
              <ProsthesisForm nextCallback={navigateNextStep} previousCallback={handlePrevious} />
            )}
            {!isOrderLoading && selectedStep === 3 && (
              <FinalisationForm
                submitCallback={handleSubmit}
                previousCallback={handlePrevious}
                initialOrderInfo={initialOrderInfo}
              />
            )}
          </div>
        </div>
        {!isOrderLoading && selectedStep !== 0 && (
          <div className={styles['order-form__body__aside']}>
            <Accordion data-cy="order-form-accordion" defaultIndex={defaultIndexAccordion}>
              <AccordionItem
                data-cy="order-form-accordion-file"
                status="none"
                title={t('createOrder.wizard.patient-files')}>
                <FileManagerContainer
                  orientation={FileManagerOrientationProp.PORTRAIT}
                  isReadOnly={true}
                  height={'300px'}
                  filesList={orderFiles}
                  isViewerLoading={isLoadingFiles}
                  file3DToDisplay={file3dToDisplay}
                  fileTextureToDisplay={fileTextureToDisplay}
                  fileImageToDisplay={fileImageToDisplay}
                  onClickFileCallback={(selectedFile: OrderFile) => {
                    displayFileInViewer(orderFiles, selectedFile, dispatch);
                  }}
                />
              </AccordionItem>
              {selectedStep !== 1 && (
                <AccordionItem
                  data-cy="order-form-accordion-summary"
                  status="none"
                  title={t('createOrder.summary.title')}>
                  <Summary canAddProduct={selectedStep === 2} canDeleteItem={selectedStep !== 3} />
                </AccordionItem>
              )}
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderForm;
