import { useEffect, useState } from 'react';
import { Skeleton, TeethMap } from '@anatoscope/circlestorybook';
import { useGetConnectedUserQuery } from '../../../../../../services/users-api.services';
import { Order } from '../../../../../../models/order';
import {
  computeTeethMapBubbleForItem,
  getMouth,
  computeLineColors,
  getZoneLinkProps,
  computeTeethShades
} from '../../../../../../features/order-manager/teeth-map/utils';
import { DentalNotation } from '../../../../../../enum/user';
import { useTranslation } from 'react-i18next';
import { TeethmapBubbleFeatureProps, TeethmapZoneLinkProps } from '../../../../../../models/map';
import { NotAllowedCursors } from '../../../../../../enum/map.enum';
import { PositionKey } from '../../../../../../models/position';

type TeethmapDisplayProps = {
  order: Order;
  displayProductBubbles: boolean;
};
const TeethMapDisplay = ({ order, displayProductBubbles }: TeethmapDisplayProps) => {
  const { t } = useTranslation(['orderDetail']);
  const { data: connectedUser } = useGetConnectedUserQuery();
  const [patientMouth, setPatientMouth] = useState<{
    [key: string]: Array<Array<PositionKey>> | Array<PositionKey>;
  }>({});
  const [zoneLinkProps, setZoneLinkProps] = useState<TeethmapZoneLinkProps[]>([]);
  let bubbles: { [key: string]: TeethmapBubbleFeatureProps } = {};
  if (order && displayProductBubbles) {
    order?.items?.forEach((item) => {
      bubbles = {
        ...bubbles,
        ...computeTeethMapBubbleForItem(item)
      };
    });
  }

  useEffect(() => {
    if (order) {
      const mouth = getMouth(order);
      if (mouth) {
        setPatientMouth(mouth);
      }
      setZoneLinkProps(getZoneLinkProps(order));
    }
  }, [order]);

  return (
    <>
      {!order && <Skeleton type="square" width="100%" />}
      {order && !order.items?.length && <div>{t('tabs.detail.teethmap.noItems')}</div>}
      <TeethMap
        bubbles={bubbles}
        notation={connectedUser ? connectedUser?.dentalNotation : DentalNotation.ISO}
        patientMouth={patientMouth}
        zoneLinkProps={zoneLinkProps}
        lineAndNumberColors={computeLineColors(order?.items)}
        teethShades={computeTeethShades(order?.items)}
        hiddenBubbles={!displayProductBubbles}
        cursors={NotAllowedCursors}
        displayShadows={false}
        isStrokeTeeth={true}
        data-cy="teethmap"
      />
    </>
  );
};

export default TeethMapDisplay;
