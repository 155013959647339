import {
  FilterProps,
  SortDirection,
  SortReducer,
  TypeColWithNamePropertyPlatform
} from '../../models/datagrid';
import i18next from 'i18next';
import { formatDate, getLocalizedProperty, isClinic } from '../../utils/utils';
import { CommonTypes } from '../../models/common-types';
import {
  BubblePicto,
  Button,
  Chips,
  IconButton,
  Link,
  Tooltip,
  TooltipContent
} from '@anatoscope/circlestorybook';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import moment from 'moment';
import { Order, OrderItem } from '../../models/order';
import {
  WorkflowModelingStepEnum,
  WorkflowPostModelingStepEnum,
  WorkflowStepEnum
} from '../../enum/workflow-step';
import { isDeliveryStep, isModelingStep } from '../../utils/order.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faClockRotateLeft, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { TypeSortInfo } from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import stylesDataGrid from './datagrid-feature.module.scss';
import { ManufacturingOrder } from '../../models/manufacturing-order';
import { ColorPropsEnum } from '../../enum/color.enum';
import { ProductForDatagrid, ProductInOrder } from '../../models/product';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { ToastType } from '../../enum/feedback';
import { splitOrderNumber } from '../order-manager/teeth-map/utils';
import { isCategoryProvisional } from '../order-form/utils';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter/index';
import { AppDispatch } from '../../store';
import { getColumnOptions } from './datagrid.utils';
import { ManufacturingComponentView } from '../../models/component.tsx';

export type ActionBtn<T = Order | ManufacturingOrder | ManufacturingComponentView> = {
  label: string;
  className?: string;
  iconLeft?: string;
  onClick: (data: T) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  'data-cy'?: string;
};

export const colOrderNumber = <T extends Order | ManufacturingOrder | ManufacturingComponentView>(
  dispatch: AppDispatch,
  actionBtn?: ActionBtn<T>
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'orderNumber',
      i18next.t('datagrid.columns.orderNumber', { ns: 'production' }),
      1
    ),
    minWidth: 170,
    maxWidth: 170,
    type: 'number',
    headerAlign: 'start',
    showColumnMenuTool: false,
    render: ({ value, data }) => {
      const orderNumberSplit = splitOrderNumber(data.orderNumber);
      return (
        <div
          className={
            stylesDataGrid[
              data.isInError
                ? 'datagrid-feature__orderNumber--error'
                : 'datagrid-feature__orderNumber'
            ]
          }>
          <Link href={`/order/${data.orderNumber}/detail`}>
            {orderNumberSplit.beforeLastPart}
            <span style={{ fontWeight: 'bold' }}>{orderNumberSplit.lastPart}</span>
          </Link>
          <IconButton
            color={ColorPropsEnum.GREY}
            iconSize={'sm'}
            faIconClass="copy"
            iconStyle="regular"
            onClick={() => {
              navigator.clipboard.writeText(value);
              dispatch(
                feedbackActions.setToast({
                  message: i18next.t('toast.copiedToClipboard', {
                    orderNumber: value,
                    ns: 'common'
                  }),
                  type: ToastType.SUCCESS
                })
              );
            }}
            radius="full"
          />
          {actionBtn && data.comments?.length && (
            <IconButton
              faIconClass="comment"
              data-cy="add-comment"
              iconStyle="regular"
              onClick={() => {
                actionBtn.onClick(data);
              }}
              radius="full"
            />
          )}
        </div>
      );
    }
  };
};

export const colDentistName: TypeColWithNamePropertyPlatform = {
  ...getColumnOptions('dentistName', i18next.t('datagrid.columns.dentist', { ns: 'dashboard' }), 1),
  showColumnMenuTool: false,
  minWidth: 60,
  cellDOMProps: () => ({
    style: {
      textAlign: 'left',
      textTransform: 'capitalize'
    }
  }),
  render: ({ value, data }) => {
    const order = data as ManufacturingOrder;
    return <Link href={`/dentist/detail/${order.dentistEmail}`} label={value}></Link>;
  }
};

export const colAssignee = <T extends Order | ManufacturingOrder | ManufacturingComponentView>(
  actionBtn?: ActionBtn<T>
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'assigneeName',
      i18next.t('datagrid.columns.assignee', { ns: 'dashboard' }),
      1
    ),
    minWidth: 100,
    showColumnMenuTool: false,
    render: ({ data, value }: { value: string; data: T }) => {
      if (!actionBtn || value) {
        return value;
      } else {
        return (
          <Button
            label={i18next.t('assignOrderModal.action', { ns: 'dashboard' })}
            onClick={() => actionBtn.onClick(data)}
            size="s"
          />
        );
      }
    }
  };
};

export const colStatus: TypeColWithNamePropertyPlatform = {
  ...getColumnOptions('isUrgent', '', 2, false),
  textAlign: 'center',
  showColumnMenuTool: false,
  textEllipsis: false,
  maxWidth: 50,
  cellDOMProps: () => ({
    style: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '4px',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }),
  render: ({ value, data }) => {
    if (data?.isInError) {
      return (
        <Tooltip>
          <TooltipContent>
            {i18next.t('datagrid.status.inError', { ns: 'dashboard' })}
          </TooltipContent>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className={stylesDataGrid['datagrid-feature__icon--error']}
          />
        </Tooltip>
      );
    }
    if (!data?.toManufacture) {
      return (
        <Tooltip>
          <TooltipContent>
            {i18next.t('datagrid.status.notToManufacture', { ns: 'dashboard' })}
          </TooltipContent>
          <FontAwesomeIcon icon={faBan} className={stylesDataGrid['datagrid-feature__icon']} />
        </Tooltip>
      );
    }
    if (value) {
      return (
        <Tooltip>
          <TooltipContent>
            {i18next.t('datagrid.status.lateOrder', { ns: 'dashboard' })}
          </TooltipContent>
          <span className={stylesDataGrid['datagrid-feature__icon--error']}>
            <FontAwesomeIcon
              icon={faClockRotateLeft}
              className={stylesDataGrid['datagrid-feature__icon']}
            />
          </span>
        </Tooltip>
      );
    }
  }
};

export const colFamilies = (commonTypes: CommonTypes): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'families',
      i18next.t('datagrid.columns.families', { ns: 'production' }),
      2,
      false
    ),
    showColumnMenuTool: false,
    textEllipsis: false,
    minWidth: 200,
    cellDOMProps: () => ({
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '4px',
        alignItems: 'center'
      }
    }),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource:
        commonTypes.families &&
        Object.values(commonTypes.families).map((family) => {
          return {
            id: family,
            label: i18next.t(`families.${family}`, {
              ns: 'catalog'
            })
          };
        })
    },
    render: ({ data }: { data: ManufacturingOrder | Order }) => {
      if (data.families?.length > 0) {
        return data.families.map((family, index) => {
          if (family) {
            return (
              <Chips
                className={stylesDataGrid['datagrid-feature__chips']}
                key={`chip_${family.toLowerCase()}_${index}`}
                firstLabel={i18next.t(`families.${family}`, {
                  ns: 'catalog'
                })}
                color={
                  ColorPropsEnum[`FAMILY_${family.toUpperCase()}` as keyof typeof ColorPropsEnum]
                }
              />
            );
          }
        });
      }
    }
  };
};

export const colManufacturingOrderProducts = (width?: number): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'products',
      i18next.t('datagrid.columns.productType', { ns: 'dashboard' }),
      2,
      false
    ),
    showColumnMenuTool: false,
    minWidth: width,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 0,
        paddingBottom: 0
      }
    }),
    render: ({ value, data }) => {
      return renderProductsAsPicto(value, data?.id);
    }
  };
};

export const colOrderProducts: TypeColWithNamePropertyPlatform = {
  ...getColumnOptions(
    'items',
    i18next.t('datagrid.columns.productType', { ns: 'dashboard' }),
    2,
    false
  ),
  showColumnMenuTool: false,
  maxWidth: 300,
  cellDOMProps: () => ({
    style: {
      textAlign: 'left',
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: 0,
      paddingBottom: 0
    }
  }),
  render: ({ value, data }) => {
    if (value && value?.length > 0) {
      const products: ProductInOrder[] = value.map((item: OrderItem) => item.product);
      return renderProductsAsPicto(products, data.id);
    }
  }
};

export const colDate = (colName: string): TypeColWithNamePropertyPlatform => ({
  ...getColumnOptions(colName, i18next.t(`datagrid.columns.${colName}`, { ns: 'dashboard' }), 2),
  maxWidth: 115,
  showColumnMenuTool: false,
  filterEditor: DateFilter,
  filterEditorProps: () => {
    // for range and not in range operators, the index is 1 for the after field
    return {
      dateFormat: i18next.t('date.small', { ns: 'common' }),
      cancelButton: false
    };
  },
  render: ({ value }) => {
    if (value) {
      return typeof value === 'string'
        ? formatDate(new Date(value), {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
        : '-';
    }
  }
});

const renderProductsAsPicto = (products: ProductInOrder[], id: number) => {
  if (products && products?.length > 0) {
    const productsData = computeProductDisplay(products);
    return (
      <>
        {productsData.map((product, index) => (
          <Tooltip key={`prd__${id}__${product.label.toLowerCase().replaceAll(' ', '_')}_${index}`}>
            <TooltipContent data-cy="datagrid__tooltip__products">{product.label}</TooltipContent>
            <BubblePicto
              data-cy="datagrid__products"
              count={product.count}
              url={product.imageUrl}
              isDashedBorder={isCategoryProvisional(product.category)}
              color={
                ColorPropsEnum[
                  `FAMILY_${product.family
                    .toUpperCase()
                    .toUpperCase()}` as keyof typeof ColorPropsEnum
                ]
              }
            />
          </Tooltip>
        ))}
      </>
    );
  }
};

const computeProductDisplay = (products: ProductInOrder[]): ProductForDatagrid[] => {
  const productsData: ProductForDatagrid[] = [];
  const labelPropertyName = getLocalizedProperty('label') as keyof ProductInOrder;
  products.forEach(function (product) {
    const label = product[labelPropertyName] as string;
    const productsDataIndex = productsData.findIndex((data) => data.label === label);
    if (productsDataIndex >= 0) {
      productsData[productsDataIndex].count++;
    } else {
      productsData.push({
        count: 1,
        label: label,
        imageUrl: product.imageUrl,
        family: product.family,
        category: product.category!
      });
    }
  });
  return productsData;
};

export const colInstructions: TypeColWithNamePropertyPlatform = {
  ...getColumnOptions(
    'instructions',
    i18next.t('datagrid.columns.instructions', { ns: 'production' }),
    2,
    false
  ),
  minWidth: 300,
  maxWidth: 300,
  showColumnMenuTool: false,
  render: ({ value }) => {
    return value ? (
      <Tooltip>
        <TooltipContent>{value}</TooltipContent>
        <div
          style={{
            maxWidth: '290px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}>
          {value}
        </div>
      </Tooltip>
    ) : (
      '-'
    );
  }
};

// Build filters for datagrid manufacturing orders like Modeling / Manufacturing / Control / Delivery
export const buildManufacturingOrdersFilters = (
  filterValue: Array<FilterProps>,
  currentStep: WorkflowStepEnum | null = null
) => {
  let filters = '';
  // Filter orders by steps
  if (currentStep && isModelingStep(currentStep)) {
    filters = `&filter.currentStep=$in:${WorkflowStepEnum.MODELING},${WorkflowStepEnum.MODELING_ANALYZE},${WorkflowStepEnum.MODELING_PREPARE},${WorkflowStepEnum.MODELING_DESIGN},${WorkflowStepEnum.MODELING_EXPORT},${WorkflowStepEnum.MODELING_REGISTER}`;
  } else if (currentStep && isDeliveryStep(currentStep)) {
    filters = `&filter.currentStep=$in:${WorkflowStepEnum.DELIVERY},${WorkflowStepEnum.SHIPPED}`;
  } else if (currentStep) {
    filters = `&filter.currentStep=${currentStep}`;
  }
  filterValue
    .filter((filter) => filter.value !== null && filter.value !== '')
    .forEach((filter) => {
      switch (filter.name) {
        case 'orderNumber':
        case 'dentistName':
        case 'assigneeName':
          filters = `${filters}${getUrlEncodedFilter(filter)}`;
          break;
        case 'shade':
        case 'shape':
        case 'angulation':
        case 'aspect':
        case 'componentType':
        case 'material':
        case 'tooth': {
          // multi select filter
          const multiSelectValues = filter.value as Array<string>;
          filters = `${filters}&filter.products.components.${
            filter.name
          }=$in:${multiSelectValues.join(',')}`;
          break;
        }
        case 'structure': {
          const multiSelectValues = filter.value as Array<string>;
          filters = `${filters}&filter.products.components.structures.structure=$in:${multiSelectValues.join(
            ','
          )}`;
          break;
        }
        case 'family':
        case 'families': {
          // multi select filter
          const multiSelectValues = filter.value as Array<string>;
          filters = `${filters}&filter.products.family=$in:${multiSelectValues.join(',')}`;
          break;
        }
        case 'label':
          filters = `${filters}&filter.products.components.${getLocalizedProperty(
            filter.name
          )}=$ilike:${encodeURIComponent(filter.value as string)}`;
          break;
        case 'validationDate':
        case 'limitShippingDate':
        case 'expectedDate': {
          const isoDate = moment(
            filter.value,
            i18next.t('date.small', { ns: 'common' })
          ).toISOString();
          filters = `${filters}&filter.${filter.name}=$gte:${isoDate}`;
          break;
        }
        case 'shipping': {
          const isoDate = moment(
            filter.value,
            i18next.t('date.small', { ns: 'common' })
          ).toISOString();
          filters = `${filters}&filter.shipping.shippingDate=$gte:${isoDate}`;
          break;
        }
        case 'circleCadVersion': {
          filters = `${filters}&filter.circleCadVersion.label=${encodeURIComponent(
            filter.value as string
          )}`;
          break;
        }
        case 'manufacturingDate': {
          const isoDate = moment(
            filter.value,
            i18next.t('date.small', { ns: 'common' })
          ).toISOString();
          filters = `${filters}&filter.products.components.${filter.name}=$gte:${isoDate}`;
          break;
        }
        default:
          filters = `${filters}&filter.products.components.${filter.name}=$ilike:${filter.value}`;
          break;
      }
    });
  return filters;
};

// Build filters for orders datagrid (supervision, validation)
export const buildOrdersFilters = (
  filterValue: Array<FilterProps>,
  currentStep: WorkflowStepEnum | null = null
) => {
  const containsDeliveredStep = filterValue.find(
    (filter) =>
      filter.name === 'currentStep' &&
      filter.value?.includes(WorkflowPostModelingStepEnum.DELIVERED)
  );
  let filters = containsDeliveredStep ? '' : '&filter.currentStep=$not:$eq:delivered';
  // Filter orders by steps for production datagrid like Validation
  if (currentStep) {
    filters = `&filter.currentStep=${currentStep}`;
  }
  // specific filters in column datagrid
  filterValue
    .filter((filter) => filter.value !== null && filter.value !== '')
    .forEach((filter) => {
      switch (filter.name) {
        case 'currentStep': {
          // multi select filter
          const multiSelectValuesArr = filter.value as Array<string>;
          let multiSectValuesString = multiSelectValuesArr.join(',');
          if (multiSelectValuesArr.includes(WorkflowModelingStepEnum.MODELING)) {
            // display all modeling steps when i chose Modeling (to be discussed in user test)
            multiSectValuesString = multiSectValuesString.replace(
              WorkflowModelingStepEnum.MODELING,
              Object.values(WorkflowModelingStepEnum).join(',')
            );
          }
          filters = `${filters}&filter.${filter.name}=$in:${multiSectValuesString}`;
          break;
        }
        case 'families': {
          // multi select filter
          const multiSelectValues = filter.value as Array<string>;
          filters = `${filters}&filter.items.product.family=$in:${multiSelectValues.join(',')}`;
          break;
          // filter.items.product.family=$in:fixed,guards
        }
        case 'products': {
          filters = `${filters}&filter.${getLocalizedProperty('products')}=$ilike:${filter.value}`;
          break;
        }
        case 'tags': {
          // multi select filter
          const multiSelectValues = filter.value as Array<string>;
          filters = `${filters}&filter.tags=$in:${multiSelectValues.join(',')}`;
          break;
          // filter.tags=$in:tag1,tag2
        }
        case 'validationDate':
        case 'expectedDate':
        case 'limitShippingDate': {
          const isoDate = moment(
            filter.value,
            i18next.t('date.small', { ns: 'common' })
          ).toISOString();
          filters = `${filters}&filter.${filter.name}=$gte:${isoDate}`;
          break;
        }
        default:
          filters = `${filters}${getUrlEncodedFilter(filter)}`;
          break;
      }
    });
  return filters;
};

export const buildSort = (
  sortInfo: SortReducer | SortReducer[] | TypeSortInfo | TypeSortInfo[]
) => {
  let urlSort = '';
  if (sortInfo) {
    const sortsArray: SortReducer[] = (
      Array.isArray(sortInfo) ? sortInfo : [sortInfo]
    ) as SortReducer[];
    sortsArray.forEach((sort: SortReducer) => {
      if (isClinic(sort.name)) {
        urlSort = `${urlSort}&sortBy=clinicName:${
          sort.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
        }`;
      } else if (sort.name === 'shipping') {
        urlSort = `${urlSort}&sortBy=shipping.shippingDate:${
          sort.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
        }`;
      } else if (sort.name === 'modelingAction') {
        urlSort = `${urlSort}&sortBy=currentStep:${
          sort.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
        }`;
      } else if (sort.name === 'circleCadVersion') {
        urlSort = `${urlSort}&sortBy=circleCadVersion.label:${
          sort.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
        }`;
      } else if (sort.name === 'submissionDate') {
        urlSort = `${urlSort}&sortBy=submissionDate:${
          sort.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
        }&sortBy=creationDate:${
          sort.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
        }`;
      } else {
        urlSort = `${urlSort}&sortBy=${sort.name}:${
          sort.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
        }`;
      }
    });
  }
  return urlSort;
};

export const getUrlEncodedFilter = (filter: FilterProps): string =>
  `&filter.${filter.name}=$ilike:${encodeURIComponent(filter.value as string)}`;

export const rowClassname = ({
  data
}: {
  data: Order | ManufacturingOrder;
}): string | undefined => {
  if ((data.isUrgent && data.toManufacture) || data.isInError) {
    return stylesDataGrid['datagrid-feature__row--danger'];
  }
  return undefined;
};
