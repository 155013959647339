import { Box, Button, RadioList } from '@anatoscope/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { DentalNotation, Language } from '../../../../../enum/user';
import { DentistInfos } from '../../../../../models/user';
import styles from '../dentist-detail-page.module.scss';
import useForm from '../../../../../utils/useForm';
import { dentistSelector } from '../../../../../store/users/users.selectors';
import { usersActions } from '../../../../../store/users/users.reducer';
import { dentalNotations, languages } from '../../../../../utils/utils';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../../../enum/color.enum';

type Props = {
  saveCallback: (user: Partial<DentistInfos>, email: string) => void;
  closeSideNavCallback: (opened: boolean) => void;
};

const CustomizationForm = ({ saveCallback, closeSideNavCallback }: Props) => {
  const { t } = useTranslation(['dentist', 'common']);
  const dispatch = useAppDispatch();
  const user = useAppSelector(dentistSelector);

  const saveCustomizationInfo = () => {
    dispatch(
      usersActions.setDentist({
        ...user,
        ...values
      })
    );
    if (user?.email)
      saveCallback(
        {
          ...values
        },
        user.email
      );
    closeSideNavCallback(false);
  };

  const onSubmit = () => {
    saveCustomizationInfo();
  };

  const { values, handleSubmit, handleSelect } = useForm(
    {
      dentalNotation: user?.dentalNotation ? user.dentalNotation : DentalNotation.ISO,
      language: user?.language ? user.language : Language.fr_FR
    },
    onSubmit
  );

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form
        onSubmit={handleSubmit}
        className={styles['dentist-detail-page__content__sidebar__form']}>
        <RadioList
          title={t('createDentist.customization.dentalNotation')}
          options={dentalNotations}
          selectedValue={values?.dentalNotation}
          name="dentalNotation"
          onClick={(newValue: DentalNotation) => handleSelect(newValue, 'dentalNotation')}
          className={styles['create-dentist-page__form__fieldset__input']}
        />
        <RadioList
          title={t('createDentist.customization.language')}
          options={languages}
          name="language"
          selectedValue={values?.language}
          onClick={(newValue: Language) => handleSelect(newValue, 'language')}
        />
        <div className="form__submit-button form__submit-button--right">
          <Button label={t('action.update', { ns: 'common' })} type="submit" />
        </div>
      </form>
    </Box>
  );
};
export default CustomizationForm;
