import { Button, Fieldset, RadioList, TextField } from '@anatoscope/circlestorybook';
import styles from '../create-dentist-page.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { DentistRole } from '../../../../../enum/user';
import { useTranslation } from 'react-i18next';
import { newDentistSelector } from '../../../../../store/users/users.selectors';
import { usersActions } from '../../../../../store/users/users.reducer';
import useForm from '../../../../../utils/useForm';
import { ColorPropsEnum } from '../../../../../enum/color.enum';

type Props = {
  nextCallback: () => void;
};

const IdentificationForm = ({ nextCallback }: Props) => {
  const { t } = useTranslation(['dentist']);
  const dispatch = useAppDispatch();
  const dentist = useAppSelector(newDentistSelector);

  const roles = [
    {
      label: t(`user.roles.${DentistRole.DENTIST.toLowerCase()}`, { ns: 'common' }),
      value: DentistRole.DENTIST
    },
    {
      label: t(`user.roles.${DentistRole.ASSISTANT.toLowerCase()}`, { ns: 'common' }),
      value: DentistRole.ASSISTANT
    }
  ];

  const saveIdentificationInfo = () => {
    dispatch(
      usersActions.setNewDentist({
        ...dentist,
        ...values
      })
    );
  };

  const onSubmit = () => {
    saveIdentificationInfo();
    nextCallback();
  };

  const { values, errors, handleSubmit, handleSelect, handleBlur, handleChange } = useForm(
    {
      role: dentist?.role ? dentist?.role : DentistRole.DENTIST,
      email: dentist?.email || '',
      firstName: dentist?.firstName || '',
      lastName: dentist?.lastName || '',
      phoneNumber: dentist?.phoneNumber || ''
    },
    onSubmit
  );

  return (
    <form onSubmit={handleSubmit} className={styles['create-dentist-page__form']}>
      <Fieldset size="m" className={styles['create-dentist-page__form__fieldset']}>
        <>
          <RadioList
            displayedInRow={true}
            data-cy="identificationRadio"
            title={t('createDentist.identification.role')}
            options={roles}
            name="role"
            selectedValue={values.role}
            onClick={(newValue: DentistRole) => handleSelect(newValue, 'role')}
            className={styles['create-dentist-page__form__fieldset__input']}
          />
          <TextField
            label={t('createDentist.identification.email')}
            data-cy="emailInput"
            id={'email'}
            name="email"
            value={values.email}
            type={'email'}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={
              errors?.email ? t('createDentist.identification.emailMandatory') : undefined
            }
            variant={errors?.email ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['create-dentist-page__form__fieldset__input']}
            placeholder="email@circle.com"
          />
          <TextField
            id={'firstName'}
            data-cy="firstNameInput"
            label={t('createDentist.identification.firstName')}
            value={values.firstName}
            name="firstName"
            type={'text'}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={
              errors?.firstName ? t('createDentist.identification.firstNameMandatory') : undefined
            }
            variant={errors?.firstName ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['create-dentist-page__form__fieldset__input']}
            placeholder="John"
          />
          <TextField
            id={'lastName'}
            data-cy="lastNameInput"
            label={t('createDentist.identification.lastName')}
            value={values.lastName}
            name="lastName"
            type={'text'}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={
              errors?.lastName ? t('createDentist.identification.lastNameMandatory') : undefined
            }
            variant={errors?.lastName ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['create-dentist-page__form__fieldset__input']}
            placeholder="Smith"
          />
          <TextField
            label={t('createDentist.identification.phone')}
            data-cy="phoneInput"
            id={'phone'}
            name="phoneNumber"
            value={values.phoneNumber}
            type={'tel'}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={
              errors?.phoneNumber
                ? t('createDentist.identification.phoneMandatory')
                : t('createDentist.identification.helperText.phone')
            }
            variant={errors?.phoneNumber ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['create-dentist-page__form__fieldset__input']}
            placeholder={t('createDentist.identification.placeholder.phone')}
          />
        </>
      </Fieldset>
      <div className="form__submit-button form__submit-button--right">
        <Button
          data-cy="nextButton"
          label={t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};
export default IdentificationForm;
