import { useEffect } from 'react';
import { Dropdown } from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { DropdownItem } from '../../../../../models/form';
import {
  ComponentTypeProperties,
  GingivaShadeEnum,
  MaterialEnum,
  ToothShadeEnum
} from '../../../../../enum/component';
import { Component } from '../../../../../models/component';
import i18next from 'i18next';
import { Material, Shade, Shape } from '../../../../../models/common-types';
import { productCustomizationActions } from '../../../../../store/product-customization/productCustomization.reducer';
import { useAppDispatch } from '../../../../../store/hooks';

type CustomizationDropdownProps = {
  value?: string;
  error?: string;
  customizationKey: string;
  onChange: (id: number) => void;
  component: Component;
  customizationTarget?: ComponentTypeProperties;
  handleSelect: (id: number, customizationKey: string) => void;
};

const CustomizationDropdown = ({
  component,
  customizationTarget,
  value,
  error,
  customizationKey,
  onChange,
  handleSelect
}: CustomizationDropdownProps) => {
  const { t } = useTranslation(['order']);
  const dispatch = useAppDispatch();
  let dropdownValues: DropdownItem[] = [];

  const key = customizationTarget as keyof typeof component;
  const customizationProperty = component[key] as Array<Shade | Shape | Material>;

  if (component && Array.isArray(component[key]) && (component[key] as Array<object>)?.length) {
    dropdownValues = customizationProperty?.map((value) => {
      return {
        label: i18next.t(
          `${customizationTarget?.substring(0, customizationTarget.length - 1)}.${value.code}`,
          {
            ns: 'component'
          }
        ),
        value: value.id
      };
    });
  }

  useEffect(() => {
    if (!value && dropdownValues?.length === 1) {
      // only one value available for the input
      // this equals `value = dropdownValues[0].value;` but also changes the state of the useForm in <AddProductModal />
      handleSelect(dropdownValues[0].value as number, customizationKey);
      switch (customizationKey) {
        case 'teethShade':
        case 'partialShade': {
          {
            dispatch(
              productCustomizationActions.setShade(customizationProperty[0].code as ToothShadeEnum)
            );
            break;
          }
        }
        case 'gingivaShade': {
          dispatch(
            productCustomizationActions.setGingivaShade(
              customizationProperty[0].code as GingivaShadeEnum
            )
          );
          break;
        }
        case 'frameMaterial': {
          dispatch(
            productCustomizationActions.setFrameMaterial(
              customizationProperty[0].code as MaterialEnum
            )
          );
          break;
        }
      }
    }
  }, []);

  return (
    <Dropdown
      data-cy={customizationKey}
      id={customizationKey}
      data={[dropdownValues]}
      value={value}
      helperText={
        error && t(`createOrder.addProductModal.customization.${customizationKey}.mandatory`)
      }
      variant={error ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
      isDisabled={dropdownValues?.length === 1}
      label={t(`createOrder.addProductModal.customization.${customizationKey}.label`)}
      onChange={(id: number) => {
        onChange(id);
      }}
      placeholder={t(`createOrder.addProductModal.customization.${customizationKey}.placeHolder`)}
    />
  );
};

export default CustomizationDropdown;
