import { useTranslation } from 'react-i18next';
import { Box, Button, Skeleton, Text, TextareaField } from '@anatoscope/circlestorybook';
import { ColorPropsEnum } from '../../enum/color.enum';
import { useEffect, useRef } from 'react';
import styles from './comments.module.scss';
import useForm from '../../utils/useForm';
import moment from 'moment/moment';
import { useGetLabUsersQuery } from '../../services/users-api.services';
import { useAddCommentToManufacturingOrderMutation } from '../../services/manufacturing-orders-api.services';
import {
  useAddCommentToOrderMutation,
  useGetOneOrderQuery
} from '../../services/orders-api.services';
import { isValidated } from '../../utils/order.utils';

type props = {
  orderNumber: string;
  inSidebar: boolean;
  onFirstCommentAddedCallback?: () => void;
};

const Comments = ({ orderNumber, inSidebar = true, onFirstCommentAddedCallback }: props) => {
  const { t } = useTranslation(['comment']);
  const lastComment = useRef<HTMLDivElement | null>(null);

  const [addCommentToOrder, addCommentState] = useAddCommentToOrderMutation();
  const [addCommentToManufacturingOrder] = useAddCommentToManufacturingOrderMutation();
  const orderState = useGetOneOrderQuery(orderNumber);
  const labUsersState = useGetLabUsersQuery();

  const onSubmit = () => {
    addCommentToOrder({ orderNumber, comment: values.comment as string });
    if (isValidated(orderState?.data?.currentStep)) {
      addCommentToManufacturingOrder({ orderNumber, comment: values.comment as string });
    }
    handleSelect('', 'comment');
  };

  const { values, errors, handleSubmit, handleSelect, handleBlur, handleChange } = useForm(
    {
      comment: undefined
    },
    onSubmit
  );

  const getUser = (email: string) => {
    if (labUsersState.isSuccess) {
      const user = labUsersState.data?.find((user) => user.email === email);
      if (user) {
        return `${user.firstName} ${user.lastName}`;
      }
    }
    return email;
  };

  useEffect(() => {
    if (orderState?.data?.comments?.length && lastComment?.current?.scrollIntoView) {
      // scroll to the last comment in the list
      lastComment.current?.scrollIntoView();
      if (onFirstCommentAddedCallback && orderState.data.comments?.length === 1) {
        onFirstCommentAddedCallback();
      }
    }
  }, [orderState.data]);

  return (
    <div className={styles['comments-container']}>
      <Box
        color={ColorPropsEnum.WHITE}
        className={[
          styles['comments'],
          inSidebar
            ? styles['comments__comments-sidebar-height']
            : styles['comments__comments-tab-height']
        ].join(' ')}>
        {orderState?.isLoading ? (
          <Skeleton type="text" className="skeleton" width="100%" height="50px" />
        ) : !orderState.data?.comments ? (
          <Text label={t('comments.noComment')} className={styles['comments__header']} />
        ) : (
          orderState.data?.comments?.map((comment, index) => {
            return (
              <div data-cy="added-comment" key={`comments${comment.creationDate}`}>
                <div
                  key={`commentHeader${comment.creationDate}`}
                  className={styles['comments__header']}>
                  <Text
                    key={`commentEmail${comment.creationDate}`}
                    label={getUser(comment.email)}
                  />
                  <Text
                    key={`commentDate${comment.creationDate}`}
                    className={styles['comments__header__date']}
                    label={moment(comment.creationDate).format('DD MMM HH:mm')}
                  />
                </div>
                <div
                  ref={index + 1 === orderState.data?.comments?.length ? lastComment : undefined}
                  key={`commentBody${comment.creationDate}`}
                  className={styles['comments__comment']}>
                  {comment.comment.split('\n').map((commentLine, lineIndex) => {
                    return (
                      <Text
                        key={`commentValue${comment.creationDate}${lineIndex}`}
                        label={commentLine}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })
        )}
      </Box>
      <Box color={ColorPropsEnum.WHITE} className={styles['comments__comment-input-box']}>
        <form onSubmit={handleSubmit}>
          <TextareaField
            data-cy="comment"
            id={'comment'}
            name="comment"
            label={t('comments.comment')}
            value={values.comment}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={errors?.comment ? t('comments.commentMandatory') : undefined}
            variant={errors.comment ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          />
          <div className="form__submit-button form__submit-button--right">
            <Button
              label={t('comments.action')}
              isLoading={addCommentState.isLoading}
              isDisabled={orderState.isFetching}
              type="submit"
            />
          </div>
        </form>
      </Box>
    </div>
  );
};

export default Comments;
