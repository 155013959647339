import i18next from 'i18next';
import { TypeColWithNamePropertyPlatform } from '../../../../../models/datagrid';
import { Button, DropdownMenu, IconButton } from '@anatoscope/circlestorybook';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter/index';
import { ActionBtn } from '../../../../../features/datagrid/columns';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order';
import { commentsOrderAction } from '../../../../../features/datagrid/menu-entry';
import { getColumnOptions } from '../../../../../features/datagrid/datagrid.utils';
import { ManufacturingComponentView } from '../../../../../models/component.tsx';
import { Order } from '../../../../../models/order.tsx';

export const colShippingAction = <
  T extends Order | ManufacturingOrder | ManufacturingComponentView
>(
  shipBtn?: ActionBtn<T>,
  deliveredBtn?: ActionBtn<T>
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'shipping',
      i18next.t('datagrid.columns.shipping', { ns: 'production' }),
      1
    ),
    showColumnMenuTool: false,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      // for range and not in range operators, the index is 1 for the after field
      return {
        dateFormat: i18next.t('date.small', { ns: 'common' }),
        cancelButton: false
      };
    },
    render: ({ value, data }) => {
      if (value) {
        return (
          deliveredBtn && (
            <Button
              label={deliveredBtn.label}
              onClick={() => deliveredBtn.onClick(data)}
              size="s"
            />
          )
        );
      } else {
        return (
          shipBtn && <Button label={shipBtn.label} onClick={() => shipBtn.onClick(data)} size="s" />
        );
      }
    }
  };
};

export const colShippingMenu = (
  handleCommentCallback: (data: ManufacturingOrder) => void
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions('menu', '', 1, false),
    showColumnMenuTool: false,
    maxWidth: 60,
    render: ({ data }) => {
      return (
        <DropdownMenu
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton
              data-cy="datagrid-menu"
              faIconClass="ellipsis-vertical"
              isActive={active}
              radius="full"
            />
          )}
          data={[[commentsOrderAction(data, handleCommentCallback)]]}
        />
      );
    }
  };
};
