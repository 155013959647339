import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './dashboard-page.module.scss';
import { Button, SideBarModal, Tabs } from '@anatoscope/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { TabsType } from '../../../models/navigation';
import ChangeStepForm from './change-step-form/ChangeStepForm';
import {
  colCurrentStep,
  colDashboardMenu,
  colMaterial,
  colShade,
  colSubmissionDate,
  colTags,
  kpiSupervision
} from './supervision';
import { useTranslation } from 'react-i18next';
import { FilterReducer, LoadDataProps } from '../../../models/datagrid';
import {
  useGetOrdersIndicatorsQuery,
  useLazyGetOneOrderQuery,
  useLazyGetOrdersQuery,
  usePatchOrderMutation
} from '../../../services/orders-api.services';
import { datagridSettingsActions } from '../../../store/datagrids-settings/datagrids-settings.reducers';
import DatagridFeature from '../../../features/datagrid/DatagridFeature';
import { supervisionDatagridStateSelector } from '../../../store/datagrids-settings/datagrids-settings.selectors';
import {
  TypeSingleSortInfo,
  TypeSortInfo
} from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { useGetCommonTypesQuery } from '../../../services/common-types-api.services';
import {
  ActionBtn,
  buildOrdersFilters,
  buildSort,
  colAssignee,
  colDate,
  colDentistName,
  colFamilies,
  colOrderNumber,
  colOrderProducts,
  colStatus,
  rowClassname
} from '../../../features/datagrid/columns';
import AssignOrderForm from './assign-order-form/AssignOrderForm';
import KPIList from '../../../features/kpi-list/KPIList';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import { Order } from '../../../models/order';
import { usePatchManufacturingOrderMutation } from '../../../services/manufacturing-orders-api.services';
import { WorkflowStepEnum, WorkFlowStepPreModelingEnum } from '../../../enum/workflow-step';
import ExportOrdersForm from './export-orders-form/ExportOrdersForm';
import { useNavigate } from 'react-router-dom';
import { useGetConnectedUserQuery } from '../../../services/users-api.services';
import { LabUserRole } from '../../../enum/user';
import { ToastType } from '../../../enum/feedback';
import PrintOrder from '../../../features/print-order/PrintOrder';
import { useReactToPrint } from 'react-to-print';
import { getMessageError } from '../../../utils/utils';
import { getMouth } from '../../../features/order-manager/teeth-map/utils';
import UpdateTagsForm from './update-tags-form/UpdateTagsForm';
import Comments from '../../../features/comments/Comments';
import DatagridReload from '../../../features/datagrid/DatagridReload';
import { ManufacturingOrder } from '../../../models/manufacturing-order.tsx';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import { PositionKey } from '../../../models/position.tsx';

const DashboardPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['dashboard']);

  const { data: connectedUser } = useGetConnectedUserQuery();

  const [sideBarOpened, setSideBarOpened] = useState(false);
  const [displayChangeStepForm, setDisplayChangeStepForm] = useState<boolean>(false);
  const [displayAssignForm, setDisplayAssignForm] = useState<boolean>(false);
  const [displayExportForm, setDisplayExportForm] = useState<boolean>(false);
  const [displayTagsForm, setDisplayTagsForm] = useState<boolean>(false);
  const [isSuccessAssignment, setIsSuccessAssignment] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [displayCommentsForm, setDisplayCommentsForm] = useState<boolean>(false);
  const [isReloadNeeded, setIsReloadNeeded] = useState<boolean>(false);
  const [reloadDate, setReloadDate] = useState<Date>();
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  // --- Print related ---
  // In order to print, our PrintOrder needs all "final" data to render and print as soon as its needed.
  // We can't make it call routes or wait for data, the print event occurs immediately at the first render.
  const [getOneOrder] = useLazyGetOneOrderQuery();
  const [orderToPrint, setOrderToPrint] = useState<Order | null>(null);
  const [orderToPrintFull, setOrderToPrintFull] = useState<Order | null>(null);
  const [patientMouth, setPatientMouth] = useState<{
    [key: string]: Array<PositionKey> | Array<Array<PositionKey>>;
  }>({});
  const [isDataForPrintReady, setIsDataForPrintReady] = useState<boolean>(false);
  // --- End Print related ---

  const datagridSettings = useAppSelector(supervisionDatagridStateSelector);

  const [getOrders] = useLazyGetOrdersQuery();
  const [unassignOrder, { data: patchedOrder, isSuccess: isSuccessUnassignOrder }] =
    usePatchOrderMutation();
  const [unassignManufacturingOrder] = usePatchManufacturingOrderMutation();
  const [setToManufactureOrder, { isSuccess: isSuccessSetToManufacture }] = usePatchOrderMutation();
  const [
    setToManufactureManufacturingOrder,
    { isSuccess: isSuccessSetToManufactureManufacturingOrder }
  ] = usePatchManufacturingOrderMutation();
  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const { data: ordersIndicators } = useGetOrdersIndicatorsQuery('');

  useEffect(() => {
    // An order has been selected for printing
    // We need to get all info of this order (since we don't have all data in the datagrid)
    // then we compute and set all the infos PrintOrder needs, so it will have everything needed for rendering itself.
    if (orderToPrint?.orderNumber) {
      getOneOrder(orderToPrint.orderNumber)
        .unwrap()
        .then((order: Order) => {
          setOrderToPrintFull(order);
          const mouth = getMouth(order);
          if (mouth) setPatientMouth(mouth);
          setIsDataForPrintReady(true);
        })
        .catch((error) => {
          dispatch(
            feedbackActions.setToast({
              message: getMessageError(error),
              type: ToastType.DANGER
            })
          );
        });
    }
  }, [orderToPrint]);

  useEffect(() => {
    if (isDataForPrintReady) {
      document.getElementById('printTrigger')!.click();
      setIsDataForPrintReady(false);
      setSelectedOrder(null);
      setOrderToPrint(null);
    }
  }, [isDataForPrintReady]);

  useEffect(() => {
    if (isSuccessSetToManufacture) {
      if (
        !Object.values(WorkFlowStepPreModelingEnum).includes(
          selectedOrder?.currentStep as WorkFlowStepPreModelingEnum
        )
      ) {
        setToManufactureManufacturingOrder({
          orderNumber: selectedOrder?.orderNumber,
          toManufacture: !selectedOrder?.toManufacture
        });
      } else {
        dispatch(
          feedbackActions.setToast({
            message: t(
              selectedOrder?.toManufacture
                ? 'toast.setNotToBeManufactured'
                : 'toast.setToBeManufactured',
              { ns: 'dashboard' }
            ),
            type: ToastType.SUCCESS
          })
        );
      }
    }
  }, [isSuccessSetToManufacture]);

  useEffect(() => {
    if (isSuccessSetToManufactureManufacturingOrder) {
      dispatch(
        feedbackActions.setToast({
          message: t(
            selectedOrder?.toManufacture
              ? 'toast.setNotToBeManufactured'
              : 'toast.setToBeManufactured',
            { ns: 'dashboard' }
          ),
          type: ToastType.SUCCESS
        })
      );
    }
  }, [isSuccessSetToManufactureManufacturingOrder]);

  useEffect(() => {
    if (isSuccessUnassignOrder) {
      if (
        !Object.values(WorkFlowStepPreModelingEnum).includes(
          patchedOrder.currentStep as WorkFlowStepPreModelingEnum
        )
      ) {
        unassignManufacturingOrder({
          orderNumber: patchedOrder.orderNumber,
          assigneeEmail: null,
          assigneeName: null
        });
      }
      dispatch(
        feedbackActions.setToast({
          message: t('toast.unassign', { ns: 'dashboard' }),
          type: ToastType.SUCCESS
        })
      );
      setSelectedOrder(null);
    }
  }, [isSuccessUnassignOrder]);

  const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = buildOrdersFilters(filterValue!);
    const sort = buildSort(sortInfo);
    return getOrders({ page, limit, filters, sort })
      .unwrap()
      .then((orders) => {
        setReloadDate(new Date());
        return { data: orders.data, count: orders.meta.totalItems };
      })
      .catch(() => {
        return { data: [], count: 0 };
      });
  };

  const dataSource = useCallback(loadData, [
    commonTypes,
    isSuccessAssignment,
    isSuccessUnassignOrder,
    isSuccessSetToManufacture,
    isSuccessSetToManufactureManufacturingOrder,
    isReloadNeeded
  ]);

  useEffect(() => {
    if (isReloadNeeded) {
      setIsReloadNeeded(false);
    }
  }, [isReloadNeeded]);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({
        datagrid: 'supervision',
        filters: newFilterValue as FilterReducer[]
      })
    );
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    let newSort = newSortInfo as TypeSingleSortInfo;
    // For now, we reset filters at every changes
    if (Array.isArray(newSort)) {
      newSort = newSort[newSort.length - 1];
    }
    dispatch(datagridSettingsActions.setDatagridSort({ datagrid: 'supervision', sort: newSort }));
  };

  const tabs: TabsType[] = [
    {
      label: t('tabs.monitoring'),
      disabled: false,
      to: '/dashboard'
    },
    {
      label: t('tabs.statistics'),
      disabled: true,
      to: undefined
    }
  ];

  const handleEscKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClickCloseSidebar();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);

    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [handleEscKey]);

  const handleClickChangeStepButton = (order: Order) => {
    setSelectedOrder(order);
    setSideBarOpened(true);
    setDisplayChangeStepForm(true);
  };

  const handleClickAssign = (order: Order) => {
    setSelectedOrder(order);
    setSideBarOpened(true);
    setDisplayAssignForm(true);
    setIsSuccessAssignment(false);
  };

  const handleClickComment = (order: Order) => {
    setSelectedOrder(order);
    setSideBarOpened(true);
    setDisplayCommentsForm(true);
  };

  const handleChangeTags = (order: Order) => {
    setSelectedOrder(order);
    setSideBarOpened(true);
    setDisplayTagsForm(true);
  };

  const onSubmitUpdateTags = () => {
    setIsReloadNeeded(true);
    setSideBarOpened(false);
    setDisplayTagsForm(false);
  };

  const handleClickUnassign = async (order: Order) => {
    setSelectedOrder(order);
    await unassignOrder({
      orderNumber: order.orderNumber,
      assigneeEmail: null,
      assigneeName: null
    });
  };

  const handleToggleManufactureCallback = async (order: Order | ManufacturingOrder) => {
    setSelectedOrder(order as Order);
    await setToManufactureOrder({
      orderNumber: order.orderNumber,
      toManufacture: !order.toManufacture
    });
  };

  const assignActionBtn: ActionBtn<Order> = {
    label: t('datagrid.action.assign', { ns: 'production' }),
    onClick: (data: Order) => {
      handleClickAssign(data);
    }
  };

  const commentActionBtn: ActionBtn<Order> = {
    label: t('comments.comment', { ns: 'comment' }),
    onClick: (data: Order) => {
      handleClickComment(data);
    }
  };

  const handleClickCloseSidebar = () => {
    setSideBarOpened(false);
    setDisplayChangeStepForm(false);
    setDisplayAssignForm(false);
    setDisplayCommentsForm(false);
    setDisplayExportForm(false);
    setDisplayTagsForm(false);
  };

  const removeFilters = () => {
    dispatch(datagridSettingsActions.resetDatagridFilters({ datagrid: 'supervision' }));
  };

  const onSubmitAssignOrder = () => {
    setIsSuccessAssignment(true);
    setSideBarOpened(false);
    setDisplayAssignForm(false);
  };

  const onSubmitChangeStepForm = () => {
    setIsReloadNeeded(true);
    setSideBarOpened(false);
    setDisplayChangeStepForm(false);
  };

  const onSubmitExportOrder = (keepOpened: boolean) => {
    setSideBarOpened(keepOpened);
    setDisplayExportForm(keepOpened);
  };

  const handleClickPrintCallback = async (order: Order) => {
    setSelectedOrder(order);
    setOrderToPrint(order);
  };

  return (
    <>
      <div className={styles['dashboard-page__print-view']}>
        {selectedOrder && orderToPrint && (
          <div>
            {
              //@ts-expect-error as the doc says.
              <button id="printTrigger" onClick={reactToPrintFn} />
            }
            <PrintOrder
              ref={contentRef}
              order={orderToPrintFull as Order}
              connectedUser={connectedUser}
              patientMouth={patientMouth}></PrintOrder>
          </div>
        )}
      </div>

      <div className={styles['dashboard-page__normal-view']}>
        <div className={styles['dashboard-page__tab']}>
          <Tabs tabs={tabs} id={'dashboard'}></Tabs>
        </div>
        <div className={styles['dashboard-page__content']}>
          <div className={styles['dashboard-page__header']}>
            {ordersIndicators && <KPIList kpiData={kpiSupervision(ordersIndicators)} />}
            <div data-cy="action-buttons" className={styles['dashboard-page__fab']}>
              <Button
                category="tertiary"
                label={t('datagrid.removeAllFilters', { ns: 'common' })}
                onClick={removeFilters}
              />
              {connectedUser?.role === LabUserRole.SUPERVISOR && (
                <Button
                  category="secondary"
                  iconLeft="fa-file-excel"
                  data-cy="action-buttons-export"
                  label={t('exportOrdersModal.title', { ns: 'dashboard' })}
                  onClick={() => {
                    setDisplayExportForm(true);
                    setSideBarOpened(true);
                  }}
                />
              )}
              <Button
                category="primary"
                iconRight="fa-plus"
                label={t('action.createOrder', { ns: 'dashboard' })}
                onClick={() => {
                  navigate('/order/create');
                }}
              />
              {reloadDate && (
                <DatagridReload reloadDate={reloadDate} setIsReloadNeeded={setIsReloadNeeded} />
              )}
            </div>
          </div>
          {!areCommonTypesLoading && commonTypes && (
            <DatagridFeature
              key="orders"
              style={{ minHeight: 'calc(100dvh - 16rem)' }}
              dataSource={dataSource}
              filterValue={datagridSettings.filters}
              onFilterValueChange={onFilterValueChange}
              sortInfo={datagridSettings.sort}
              onSortInfoChange={onSortInfoChange}
              rowClassName={rowClassname}
              columns={[
                colStatus,
                colOrderNumber(dispatch, commentActionBtn),
                colDentistName,
                colSubmissionDate,
                colFamilies(commonTypes),
                colOrderProducts,
                colMaterial,
                colShade,
                colAssignee(assignActionBtn),
                colCurrentStep(commonTypes?.workflowSteps),
                colTags(connectedUser?.laboratory?.tags ?? []),
                colDate('limitShippingDate'),
                colDate('expectedDate'),
                colDashboardMenu(
                  handleClickChangeStepButton,
                  handleClickAssign,
                  handleClickUnassign,
                  handleToggleManufactureCallback,
                  handleClickPrintCallback,
                  handleChangeTags,
                  handleClickComment
                )
              ]}
            />
          )}
          {displayChangeStepForm && selectedOrder && (
            <SideBarModal
              title={t('changeStepModal.title', { orderNumber: selectedOrder.orderNumber })}
              isOpened={sideBarOpened}
              closeOnOutsideClick={true}
              onClose={handleClickCloseSidebar}>
              <ChangeStepForm
                onSubmitChangeStepOrderCallback={onSubmitChangeStepForm}
                orderNumber={selectedOrder.orderNumber}
                currentStep={selectedOrder.currentStep as WorkflowStepEnum}
              />
            </SideBarModal>
          )}

          {displayAssignForm && selectedOrder && (
            <SideBarModal
              title={t('assignOrderModal.title', { orderNumber: selectedOrder.orderNumber })}
              isOpened={sideBarOpened}
              closeOnOutsideClick={true}
              onClose={handleClickCloseSidebar}>
              <AssignOrderForm
                onSubmitAssignOrderCallback={onSubmitAssignOrder}
                orderNumber={selectedOrder.orderNumber}
              />
            </SideBarModal>
          )}

          {displayCommentsForm && selectedOrder && (
            <SideBarModal
              title={t('comments.title', {
                orderNumber: selectedOrder.orderNumber,
                ns: 'comment'
              })}
              isOpened={sideBarOpened}
              closeOnOutsideClick={true}
              onClose={handleClickCloseSidebar}>
              <Comments
                orderNumber={selectedOrder.orderNumber}
                inSidebar={true}
                onFirstCommentAddedCallback={() => setIsReloadNeeded(true)}
              />
            </SideBarModal>
          )}

          {displayTagsForm && selectedOrder && (
            <SideBarModal
              title={t('updateTagsModal.title', { orderNumber: selectedOrder.orderNumber })}
              isOpened={sideBarOpened}
              closeOnOutsideClick={true}
              onClose={handleClickCloseSidebar}>
              <UpdateTagsForm
                onSubmitUpdateTagsCallback={onSubmitUpdateTags}
                orderNumber={selectedOrder.orderNumber}
                currentTags={selectedOrder.tags}
                currentStep={selectedOrder.currentStep as WorkflowStepEnum}
              />
            </SideBarModal>
          )}

          {displayExportForm && (
            <SideBarModal
              title={t('exportOrdersModal.title', { ns: 'dashboard' })}
              isOpened={sideBarOpened}
              closeOnOutsideClick={true}
              onClose={handleClickCloseSidebar}>
              <ExportOrdersForm
                onSubmitCallback={(keepOpened: boolean) => {
                  onSubmitExportOrder(keepOpened);
                }}
              />
            </SideBarModal>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
