import { TypeColWithNamePropertyPlatform } from '../../../../../models/datagrid';
import i18next from 'i18next';
import { ActionBtn } from '../../../../../features/datagrid/columns';
import {
  Button,
  DropdownMenu,
  IconButton,
  Timeline,
  TimelineItem,
  Tooltip,
  TooltipContent
} from '@anatoscope/circlestorybook';
import productionStyles from '../../production-page.module.scss';
import designStyles from './design-page.module.scss';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order';
import {
  WorkflowModelingStepsAuto,
  WorkflowModelingStepEnum,
  WorkflowModelingSubSteps,
  WorkflowStepEnum
} from '../../../../../enum/workflow-step';
import {
  assignManufacturingOrderAction,
  commentsOrderAction,
  deleteAction,
  editAction,
  toggleManufactureAction,
  unAssignManufacturingOrderAction
} from '../../../../../features/datagrid/menu-entry';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { CircleCadVersionEnum } from '../../../../../enum/circle-cad-version';
import { capitalizeFirstLetter } from '../../../../../utils/utils';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { ManufacturingComponentView } from '../../../../../models/component';
import {
  getCircleCadCompliantProducts,
  isManufacturingOrderOneDesignCompliant,
  isAutomaticModelisationStep,
  isPartiallyCircleCadCompliant,
  isManufacturingOrderFullCircleCadCompliant
} from '../../../../../utils/order.utils';
import { UserInfo } from '../../../../../models/user';
import { getColumnOptions } from '../../../../../features/datagrid/datagrid.utils';

type TooltippedIconButton = {
  tooltip: string;
  faIconClass: string;
  onClick: (order: ManufacturingOrder) => void;
  radius?: string;
};

export const colCircleCadVersion: TypeColWithNamePropertyPlatform = {
  ...getColumnOptions(
    'circleCadVersion',
    i18next.t('datagrid.columns.circleCadVersion', { ns: 'production' })
  ),
  showColumnMenuTool: false,
  minWidth: 87,
  maxWidth: 87,
  filterEditor: SelectFilter,
  filterEditorProps: {
    dataSource: Object.values(CircleCadVersionEnum).map((version) => {
      return {
        id: version,
        label: version
      };
    })
  },
  cellDOMProps: () => ({
    style: {
      textAlign: 'left'
    }
  }),
  render: ({ value }) => {
    if (value) {
      return value.label;
    } else {
      return '-';
    }
  }
};

export const colAssignee = <T extends ManufacturingOrder | ManufacturingComponentView>(
  handleClickAssign: (data: T) => void
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'assigneeName',
      i18next.t('datagrid.columns.assignee', { ns: 'production' })
    ),
    showColumnMenuTool: false,
    defaultFlex: 1,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        textTransform: 'capitalize'
      }
    }),
    render: ({ data, value }: { data: T; value: string }) => {
      if (value) {
        return value;
      } else {
        return (
          <Button
            data-cy="datagrid-assign-button"
            label={i18next.t('datagrid.action.assign', { ns: 'production' })}
            onClick={() => handleClickAssign(data)}
            size="s"
            category="secondary"
            isDisabled={isAutomaticModelisationStep(data.currentStep)}
          />
        );
      }
    }
  };
};

const isCadSessionInProgress = (manufacturingOrder: ManufacturingOrder): boolean =>
  !!(
    manufacturingOrder.streamingParameters &&
    !WorkflowModelingStepsAuto.includes(manufacturingOrder.currentStep)
  );

export const colActions = (
  user: UserInfo,
  handleCircleCadAnalyze: (manufacturingOrder: ManufacturingOrder) => void,
  handleCircleCadLaunch: (manufacturingOrder: ManufacturingOrder) => void,
  handleOneDesignLaunch: (manufacturingOrder: ManufacturingOrder) => void,
  handleManufacturingBtnClick: (manufacturingOrder: ManufacturingOrder) => void,
  handleJoinSession: (manufacturingOrder: ManufacturingOrder) => void
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'modelingAction',
      i18next.t(`datagrid.columns.${WorkflowStepEnum.MODELING}`, { ns: 'production' }),
      3
    ),
    showColumnMenuTool: false,
    minWidth: 670,
    render: ({ data }: { data: ManufacturingOrder }) => {
      const areActionsDisabled =
        [CircleCadVersionEnum.BETA, CircleCadVersionEnum.ALPHA].includes(
          data.circleCadVersion?.label as CircleCadVersionEnum
        ) && !user?.isAuthorizedCircleCADBeta;

      const circleCadButton = getCircleCadActionButton(
        data,
        handleCircleCadAnalyze,
        handleCircleCadLaunch,
        handleJoinSession,
        areActionsDisabled
      );
      const oneDesignButtons = getOneDesignActionButtons(
        data,
        handleOneDesignLaunch,
        handleManufacturingBtnClick,
        handleJoinSession
      );

      const isOneDesignManufacturingOrder: boolean | undefined =
        isManufacturingOrderOneDesignCompliant(data);
      const isCurrentStepAuto: boolean = WorkflowModelingStepsAuto.includes(data.currentStep);

      const isCurrentStep = (step: string): boolean => {
        return data.currentStep === step;
      };
      const stepIndex: number = WorkflowModelingSubSteps.findIndex(
        (step) => step === data.currentStep
      );
      const isBeforeStepIndex = (i: number) => i < stepIndex;

      return (
        <Timeline
          className={designStyles['design-page__timeline-design']}
          alignOtherChildrenLeft={true}>
          {data.circleCadVersion
            ? // CIRCLE CAD TIMELINE
              WorkflowModelingSubSteps.map((step: string, i: number) => {
                return (
                  <TimelineItem
                    key={`${data.id}-${step}`}
                    data-key={`comp-${data.id}-${step}`}
                    isInProgress={
                      (isCurrentStep(step) && isCurrentStepAuto) ||
                      (isCurrentStep(step) && isCadSessionInProgress(data)) ||
                      (stepIndex === -1 && i === 0 && !isPartiallyCircleCadCompliant(data)) // MODELING step for order with all compliant products circle cad, is a temporary step that will very quickly go into MODELING_ANALYZE. We can consider that MODELING_ANALYZE is in progress
                    }
                    isSuccess={!isCurrentStep(step) && isBeforeStepIndex(i)}
                    isPending={
                      (isCurrentStep(step) &&
                        !isCurrentStepAuto &&
                        !isBeforeStepIndex(i) &&
                        !isCadSessionInProgress(data)) ||
                      (stepIndex === -1 && i === 0 && isPartiallyCircleCadCompliant(data)) // MODELING step, means pending analyze for mixed order
                    }
                    isFirstItem={i === 0}
                    customDividerWidth={16}
                    customLabelWidth={38}
                    isInProgressIcon={faArrowsRotate}
                    label={i18next.t(`datagrid.designStatus.${step.toLowerCase()}`, {
                      ns: 'production'
                    })}
                    data={data}
                  />
                );
              })
            : isOneDesignManufacturingOrder && (
                // ONE DESIGN TIMELINE
                <TimelineItem
                  styles={{ justifyContent: 'flexEnd' }}
                  key={`${data.id}-${WorkflowStepEnum.MODELING_DESIGN}`}
                  data-key={`comp-${data.id}-${WorkflowStepEnum.MODELING_DESIGN}`}
                  isInProgress={isCurrentStepAuto || isCadSessionInProgress(data)}
                  isSuccess={
                    data.currentStep !== WorkflowStepEnum.MODELING_DESIGN && stepIndex !== -1
                  }
                  isPending={
                    (data.currentStep === WorkflowStepEnum.MODELING_DESIGN &&
                      !isCurrentStepAuto &&
                      !isCadSessionInProgress(data)) ||
                    stepIndex === -1
                  }
                  isFirstItem={true}
                  customDividerWidth={16}
                  customLabelWidth={38}
                  isInProgressIcon={faArrowsRotate}
                  label={i18next.t(
                    `datagrid.designStatus.${WorkflowStepEnum.MODELING_DESIGN.toLowerCase()}`,
                    {
                      ns: 'production'
                    }
                  )}
                  data={data}
                />
              )}
          <div className={designStyles['design-page__timeline-design__buttons']}>
            {user && data.circleCadVersion && circleCadButton && (
              // CIRCLE CAD ACTION BUTTONS
              <Tooltip key={`tooltip`}>
                <TooltipContent>{circleCadButton.tooltip}</TooltipContent>
                <Button
                  key={`btn__${circleCadButton.label.replaceAll(' ', '_')}`}
                  label={circleCadButton.label}
                  isDisabled={areActionsDisabled}
                  onClick={() => circleCadButton.onClick(data)}
                  size="s"
                  data-cy={circleCadButton['data-cy'] || ''}
                  iconLeft={circleCadButton.iconLeft}
                />
              </Tooltip>
            )}
            {isOneDesignManufacturingOrder &&
              oneDesignButtons.length > 0 &&
              oneDesignButtons.map((oneDesignButton) => (
                // ONE DESIGN ACTION BUTTONS
                <Tooltip key={`tooltip`}>
                  <TooltipContent>{oneDesignButton.tooltip}</TooltipContent>
                  <Button
                    className={oneDesignButton.className}
                    key={`btn__${oneDesignButton.label.replaceAll(' ', '_')}`}
                    label={oneDesignButton.label}
                    isDisabled={oneDesignButton.isDisabled}
                    onClick={() => oneDesignButton.onClick(data)}
                    size="s"
                    data-cy={oneDesignButton['data-cy'] || ''}
                    iconLeft={oneDesignButton.iconLeft}
                  />
                </Tooltip>
              ))}
            {!circleCadButton && oneDesignButtons.length === 0 && (
              // CURRENT STEP (NO ACTION)
              <Tooltip>
                <TooltipContent>
                  {data.currentStep === WorkflowModelingStepEnum.MODELING // temporary MODELING step, will go into MODELING_ANALYZE
                    ? i18next.t('tooltip.steps.modeling_analyze', {
                        ns: 'production'
                      })
                    : i18next.t(`tooltip.steps.${data.currentStep}`, {
                        ns: 'production'
                      })}
                </TooltipContent>
                <p>
                  {data.currentStep === WorkflowModelingStepEnum.MODELING // temporary MODELING step, will go into MODELING_ANALYZE
                    ? i18next.t('order.steps.modeling_analyze', { ns: 'common' })
                    : i18next.t(`order.steps.${data.currentStep}`, { ns: 'common' })}
                </p>
              </Tooltip>
            )}
          </div>
        </Timeline>
      );
    }
  };
};

export const colFileActions = (
  handleUploadCallback: (data: ManufacturingOrder) => void,
  handleDownloadCallback: (data: ManufacturingOrder) => void,
  isDownloadingFiles: boolean
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'fileAction',
      i18next.t('datagrid.columns.files', { ns: 'production' }),
      2,
      false
    ),
    showColumnMenuTool: false,
    minWidth: 100,
    maxWidth: 100,
    render: ({ data }: { data: ManufacturingOrder }) => {
      const iconButtons: TooltippedIconButton[] = getFileButtons(
        data,
        handleUploadCallback,
        handleDownloadCallback
      );
      return (
        <div className={designStyles['design-page__datagrid__column__status']}>
          {iconButtons?.map((iconButton) => {
            return (
              <Tooltip key={`tooltip__${iconButton.faIconClass}`}>
                <TooltipContent>{iconButton.tooltip}</TooltipContent>
                <IconButton
                  faIconClass={iconButton.faIconClass}
                  data-cy={iconButton.faIconClass}
                  onClick={() => iconButton.onClick(data)}
                  iconSize="xl"
                  radius="full"
                  color={ColorPropsEnum.PRIMARY}
                  isDisabled={isDownloadingFiles}
                  className={productionStyles['production-page__datagrid__icon-button']}
                />
              </Tooltip>
            );
          })}
        </div>
      );
    }
  };
};

export const colDesignMenu = (
  handleToggleManufactureCallback: (data: ManufacturingOrder) => Promise<void>,
  handleClickAssign: (data: ManufacturingOrder) => void,
  handleClickUnassign: (data: ManufacturingOrder) => Promise<void>,
  handleCommentCallback: (data: ManufacturingOrder) => void
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions('menu', '', 1, false),
    showColumnMenuTool: false,
    maxWidth: 60,
    render: ({ data }) => {
      return (
        <DropdownMenu
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton
              data-cy="datagrid-menu"
              faIconClass="ellipsis-vertical"
              isActive={active}
              radius="full"
            />
          )}
          data={[
            [
              editAction,
              data && toggleManufactureAction(data, handleToggleManufactureCallback),
              !data.assigneeEmail && !data.assigneeName
                ? assignManufacturingOrderAction(data, handleClickAssign)
                : unAssignManufacturingOrderAction(data, handleClickUnassign),
              commentsOrderAction(data, handleCommentCallback)
            ],
            [deleteAction]
          ]}
        />
      );
    }
  };
};

const getFileButtons = (
  manufacturingOrder: ManufacturingOrder,
  handleUploadCallback: (data: ManufacturingOrder) => void,
  handleDownloadCallback: (data: ManufacturingOrder) => void
): TooltippedIconButton[] => {
  const uploadIconBtn: TooltippedIconButton = {
    tooltip: i18next.t('tabs.design.upload_tooltip', { ns: 'production' }),
    faIconClass: 'upload',
    onClick: (manufacturingOrder: ManufacturingOrder) => {
      handleUploadCallback(manufacturingOrder);
    }
  };
  const downloadIconBtn: TooltippedIconButton = {
    tooltip: i18next.t('tabs.design.download_tooltip', { ns: 'production' }),
    faIconClass: 'download',
    onClick: (manufacturingOrder: ManufacturingOrder) => {
      handleDownloadCallback(manufacturingOrder);
    }
  };

  let iconButtons: TooltippedIconButton[] = [];
  if (
    isPartiallyCircleCadCompliant(manufacturingOrder) &&
    ![
      WorkflowStepEnum.MODELING_ANALYZE as string,
      WorkflowStepEnum.MODELING_EXPORT as string
    ].includes(manufacturingOrder.currentStep)
  ) {
    // If there are some products in order that are CircleCAD compliant
    // User should be able to download patient files to design outside CircleCAD
    // But also to upload updated patient files to take other prostheses into account while designing with Circle CAD
    iconButtons = [downloadIconBtn, uploadIconBtn];
  } else if (
    isManufacturingOrderOneDesignCompliant(manufacturingOrder) ||
    (!isManufacturingOrderOneDesignCompliant(manufacturingOrder) &&
      !isManufacturingOrderFullCircleCadCompliant(manufacturingOrder))
  ) {
    // For One Design, user should be able to download patient files to design outside CircleCAD in case One Design not works
    // For non compliant order, user should be also able to download patient files
    iconButtons = [downloadIconBtn];
  }
  return iconButtons;
};

const getCircleCadActionButton = (
  manufacturingOrder: ManufacturingOrder,
  handleAnalyzeButtonClick: (manufacturingOrder: ManufacturingOrder) => void,
  handleCircleCadLaunchBtnClick: (manufacturingOrder: ManufacturingOrder) => void,
  onJoinSessionBtnClick: (manufacturingOrder: ManufacturingOrder) => void,
  areActionsDisabled: boolean
): ActionBtn<ManufacturingOrder> | undefined => {
  const prepareBtn: ActionBtn<ManufacturingOrder> = {
    label: i18next.t('tabs.design.prepare', { ns: 'production' }),
    iconLeft: manufacturingOrder.streamingParameters ? 'fa-eye' : undefined,
    tooltip: areActionsDisabled
      ? i18next.t('tabs.design.disabled_actions.prepare_with_circleCad', {
          ns: 'production',
          version: capitalizeFirstLetter(manufacturingOrder.circleCadVersion?.label)
        })
      : i18next.t('tabs.design.actions.prepare_with_circleCad', { ns: 'production' }),
    onClick: (manufacturingOrder: ManufacturingOrder) => {
      handleCircleCadLaunchBtnClick(manufacturingOrder);
    },
    'data-cy': 'circleCadPrepare'
  };

  const joinCadSessionBtn: ActionBtn<ManufacturingOrder> = {
    label: i18next.t('tabs.design.circleCad_running', { ns: 'production' }),
    iconLeft: 'fa-eye',
    tooltip: areActionsDisabled
      ? i18next.t('tabs.design.disabled_actions.prepare_with_circleCad', {
          ns: 'production',
          version: capitalizeFirstLetter(manufacturingOrder.circleCadVersion?.label)
        })
      : i18next.t('tabs.design.actions.join_session_cad', { ns: 'production' }),
    onClick: (manufacturingOrder: ManufacturingOrder) => {
      onJoinSessionBtnClick(manufacturingOrder);
    },
    'data-cy': 'circleCadJoinSession'
  };

  const designBtn: ActionBtn<ManufacturingOrder> = {
    label: i18next.t('tabs.design.design', { ns: 'production' }),
    iconLeft: manufacturingOrder.streamingParameters ? 'fa-eye' : undefined,
    isLoading: false,
    tooltip: areActionsDisabled
      ? i18next.t('tabs.design.disabled_actions.design_with_circleCad', {
          ns: 'production',
          version: capitalizeFirstLetter(manufacturingOrder.circleCadVersion?.label)
        })
      : i18next.t('tabs.design.actions.design_with_circleCad', { ns: 'production' }),
    onClick: (manufacturingOrder: ManufacturingOrder) => {
      handleCircleCadLaunchBtnClick(manufacturingOrder);
    },
    'data-cy': 'circleCadDesign'
  };

  const analyzeBtn: ActionBtn = {
    label: i18next.t('datagrid.action.analyze', { ns: 'production' }),
    isLoading: false,
    tooltip: areActionsDisabled
      ? i18next.t('tabs.design.disabled_actions.analyze_with_circleCad', {
          ns: 'production',
          version: capitalizeFirstLetter(manufacturingOrder.circleCadVersion?.label)
        })
      : i18next.t('tabs.design.actions.analyze_with_circleCad', { ns: 'production' }),
    onClick: () => {
      handleAnalyzeButtonClick(manufacturingOrder);
    },
    'data-cy': 'circleCadAnalyze'
  };

  const circleCadCompliantProducts = getCircleCadCompliantProducts(manufacturingOrder);
  switch (true) {
    case isCadSessionInProgress(manufacturingOrder):
      return joinCadSessionBtn;
    // Current step is explicitly DESIGN and products are circle cad compliant
    case manufacturingOrder.currentStep === WorkflowStepEnum.MODELING_DESIGN &&
      circleCadCompliantProducts?.length > 0:
      return designBtn;
    // Current step is explicitly PREPARE or we have no explicit substep but all products are CircleCAD compliant
    case manufacturingOrder.currentStep === WorkflowStepEnum.MODELING_PREPARE:
      return prepareBtn;
    // For mixed order, display button Analyze
    case manufacturingOrder.currentStep === WorkflowStepEnum.MODELING &&
      circleCadCompliantProducts?.length < manufacturingOrder.products?.length:
      return analyzeBtn;
    default:
      return undefined;
  }
};

const getOneDesignActionButtons = (
  manufacturingOrder: ManufacturingOrder,
  handleOneDesignLaunchBtnClick: (manufacturingOrder: ManufacturingOrder) => void,
  handleManufacturingBtnClick: (manufacturingOrder: ManufacturingOrder) => void,
  onJoinSessionBtnClick: (manufacturingOrder: ManufacturingOrder) => void
): ActionBtn<ManufacturingOrder>[] => {
  if (manufacturingOrder.orderNumber === 'OR-241113-LA160') {
    console.log('manufacturingOrder', manufacturingOrder);
  }
  const oneDesignBtn: ActionBtn = {
    label: i18next.t('datagrid.action.design', { ns: 'production' }),
    tooltip: i18next.t('tabs.design.actions.one_design', { ns: 'production' }),
    onClick: () => {
      handleOneDesignLaunchBtnClick(manufacturingOrder);
    },
    'data-cy': 'oneDesign'
  };

  const sendToManufacturingBtn: ActionBtn = {
    className: designStyles['design-page__button'],
    label: i18next.t('datagrid.action.send_manufacturing', { ns: 'production' }),
    tooltip: i18next.t('tabs.design.actions.send_manufacturing', { ns: 'production' }),
    isDisabled: true,
    onClick: () => {
      handleManufacturingBtnClick(manufacturingOrder);
    },
    'data-cy': 'sendToManufacturing'
  };

  const joinOneDesignSessionBtn: ActionBtn<ManufacturingOrder> = {
    label: i18next.t('tabs.design.circleCad_running', { ns: 'production' }),
    iconLeft: 'fa-eye',
    tooltip: i18next.t('tabs.design.actions.join_session_one', { ns: 'production' }),
    onClick: (manufacturingOrder: ManufacturingOrder) => {
      onJoinSessionBtnClick(manufacturingOrder);
    },
    'data-cy': 'oneDesignJoinSession'
  };

  switch (true) {
    case isCadSessionInProgress(manufacturingOrder):
      return [joinOneDesignSessionBtn, sendToManufacturingBtn];
    // Current step is explicitly DESIGN
    case manufacturingOrder.currentStep === WorkflowStepEnum.MODELING:
    case manufacturingOrder.currentStep === WorkflowStepEnum.MODELING_DESIGN:
      return [oneDesignBtn, sendToManufacturingBtn];
    default:
      return [];
  }
};
