import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  AuthenticationReducer,
  UserAuthentication,
  UserAuthenticationPayload
} from '../../models/user';
import {
  forceRefreshToken,
  signIn,
  signInWithUserCustomToken,
  signOut
} from '../../services/firebase.services';
import i18next from '../../i18n';
import { ErrorFeedBack } from '../../models/feedback';
import { ErrorCode, ErrorUIType } from '../../enum/feedback';
import { setClaims } from '../../services/security-api.services';
import { UserCredential } from 'firebase/auth';
import { HttpStatusCode } from 'axios';
import { history } from '../../models/history';

const createInitialState = (): AuthenticationReducer => {
  return {
    // initialize state from local storage to enable user to stay logged in
    isAuthenticated: undefined,
    connectedUser: undefined
  };
};

const createReducers = () => {
  const saveUserState = (
    state: AuthenticationReducer,
    action: PayloadAction<UserAuthenticationPayload>
  ) => {
    if (action.payload.firebaseUser !== 'null' && action.payload.isAuthorized) {
      const user = JSON.parse(action.payload.firebaseUser);
      state.connectedUser = {
        ...state.connectedUser,
        ...{
          email: user.email,
          photoUrl: user.providerData[0].photoUrl
        }
      };
      state.isAuthenticated = true;
    } else {
      state.isAuthenticated = false;
      state.connectedUser = undefined;
    }
  };

  return {
    saveUserState
  };
};

const createExtraActions = () => {
  const login = () => {
    return createAsyncThunk(
      `${name}/login`,
      async ({ username, password }: UserAuthentication, thunkAPI) => {
        const email = username.toLowerCase();
        return signIn(email, password)
          .then(async (userCredential: UserCredential) => {
            // Get the id token to update the claims with MS-Users.
            const idToken = await userCredential.user.getIdToken();
            // Update the claims with the access control data needed.
            const customToken = await setClaims(email, idToken);
            if (customToken.status === HttpStatusCode.Ok) {
              await signInWithUserCustomToken(customToken.data);
              // Force the token previously created to get the new claims.
              await forceRefreshToken();
              return {
                isAuthenticatedFirebase: true,
                isAuthenticatedWithClaims: true
              };
            } else {
              // This error concerns control access.
              // So, we dont precise the origin of error for security reason.
              // (email not found for example)
              return thunkAPI.rejectWithValue({
                type: ErrorUIType.ATTENTION_BOX,
                message: i18next.t('error.failed', { ns: 'auth' })
              });
            }
          })
          .catch((err) => {
            let error: ErrorFeedBack = {
              feedbackType: ErrorUIType.ATTENTION_BOX,
              message: Object.values(ErrorCode).includes(err.code)
                ? i18next.t(err.code, { ns: 'error' })
                : i18next.t('error.failed', { ns: 'auth' })
            };

            switch (err.code) {
              case ErrorCode.auth_wrong_password:
              case ErrorCode.auth_user_not_found:
                error.feedbackType = ErrorUIType.ATTENTION_BOX;
                break;
              case ErrorCode.auth_invalid_email:
                error.feedbackType = ErrorUIType.FIELD;
                break;
              case ErrorCode.auth_too_many_requests:
                error.feedbackType = ErrorUIType.ATTENTION_BOX;
                break;
              case ErrorCode.auth_missing_password:
                error.feedbackType = ErrorUIType.FIELD;
                break;
              default:
                error = {
                  feedbackType: ErrorUIType.ATTENTION_BOX,
                  message: i18next.t('error.failed', { ns: 'auth' })
                };
            }
            return thunkAPI.rejectWithValue(error);
          });
      }
    );
  };
  const logout = () => {
    return createAsyncThunk(`${name}/logout`, async () => {
      await signOut();
    });
  };

  return { login: login(), logout: logout() };
};

const createExtraReducers = () => {
  return (builder: ActionReducerMapBuilder<AuthenticationReducer>) => {
    const login = () => {
      const { pending, fulfilled, rejected } = extraActions.login;
      builder
        .addCase(pending, (state) => {
          state.isAuthenticated = false;
        })
        .addCase(fulfilled, (state, action) => {
          // get return url from location state or default to home page
          const { from } = history.location?.state || { from: { pathname: '/' } };
          state.isAuthenticated =
            action.payload.isAuthenticatedFirebase && action.payload.isAuthenticatedWithClaims;
          if (history.navigate) history.navigate(from);
        })
        .addCase(rejected, (state) => {
          state.isAuthenticated = false;
        });
    };
    const logout = () => {
      const { fulfilled } = extraActions.logout;
      builder.addCase(fulfilled, (state) => {
        state.isAuthenticated = false;
        state.connectedUser = undefined;
        if (history.navigate) history.navigate('/login');
      });
    };
    login();
    logout();
  };
};

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraReducers = createExtraReducers();
export const authSlice = createSlice({ name, initialState, reducers, extraReducers });
const extraActions = createExtraActions();

export const authActions = { ...authSlice.actions, ...extraActions };
