export enum AdminRole {
  ADMIN = 'ADMIN',
  SUPPORT = 'SUPPORT'
}

export enum DentistRole {
  DENTIST = 'DENTIST',
  ASSISTANT = 'ASSISTANT'
}

export enum DesignerRole {
  DESIGNER = 'DESIGNER'
}

export enum LabUserRole {
  LAB_DESIGNER = 'LAB_DESIGNER',
  SUPERVISOR = 'SUPERVISOR',
  PROSTHETIST = 'PROSTHETIST',
  TECHNICIAN = 'TECHNICIAN'
}

export enum DentalNotation {
  ISO = 'ISO',
  US = 'US'
}

export enum Language {
  fr_FR = 'fr_FR',
  en_GB = 'en_GB'
}

export enum CalendarState {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED'
}

export enum Country {
  CA = 'CA',
  FR = 'FR',
  DE = 'DE',
  US = 'US'
}

export enum UserDetailsRefererEnum {
  USER_DETAILS = 'USER_DETAILS',
  PROFILE_PAGE = 'PROFILE_PAGE'
}
