import { TypeColWithNamePropertyPlatform } from '../../../../../models/datagrid';
import i18next from 'i18next';
import { Button, DropdownMenu, IconButton } from '@anatoscope/circlestorybook';
import { ManufacturingOrder } from '../../../../../models/manufacturing-order';
import { formatDate } from '../../../../../utils/utils';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { commentsOrderAction } from '../../../../../features/datagrid/menu-entry';
import { getColumnOptions } from '../../../../../features/datagrid/datagrid.utils';

export const colControlAction = (
  callback: (data: ManufacturingOrder) => void
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions(
      'controlledAt',
      i18next.t('datagrid.columns.control', { ns: 'production' }),
      1
    ),
    showColumnMenuTool: false,
    filterEditor: DateFilter,
    maxWidth: 180,
    filterEditorProps: () => {
      // for range and not in range operators, the index is 1 for the after field
      return {
        dateFormat: i18next.t('date.small', { ns: 'common' }),
        cancelButton: false
      };
    },
    render: ({ data }) => {
      if (data?.controlledAt) {
        return formatDate(new Date(data.controlledAt));
      } else {
        return (
          <Button
            label={i18next.t('datagrid.action.control', { ns: 'production' })}
            onClick={() => callback(data)}
            size="s"
          />
        );
      }
    }
  };
};

export const colControlMenu = (
  handleCommentCallback: (data: ManufacturingOrder) => void
): TypeColWithNamePropertyPlatform => {
  return {
    ...getColumnOptions('menu', '', 1, false),
    showColumnMenuTool: false,
    maxWidth: 60,
    render: ({ data }) => {
      return (
        <DropdownMenu
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton
              data-cy="datagrid-menu"
              faIconClass="ellipsis-vertical"
              isActive={active}
              radius="full"
            />
          )}
          data={[[commentsOrderAction(data, handleCommentCallback)]]}
        />
      );
    }
  };
};
