import { ColorPropsEnum, MaterialColorEnum } from '../enum/color.enum';
import { ComponentType, MaterialEnum } from '../enum/component';
import {
  WorkflowManufacturingStepEnum,
  WorkflowPostModelingStepEnum,
  WorkflowStepEnum,
  WorkFlowStepPreModelingEnum
} from '../enum/workflow-step';
import { ManufacturingOrder, ManufacturingOrderProduct } from '../models/manufacturing-order';
import { Order, OrderItem, OrderItemComponent } from '../models/order';
import { publicImagesUrl } from './utils';

export const defaultProductIconUrl = `${publicImagesUrl}gear-solid.svg`;

export const isSubmittingStep = (currentStep: string | undefined): boolean =>
  (currentStep && currentStep === WorkflowStepEnum.SUBMITTING) as boolean;

export const isSubmitted = (currentStep?: WorkflowStepEnum): boolean =>
  (currentStep && currentStep !== WorkFlowStepPreModelingEnum.SUBMITTING) as boolean;

export const isValidationStep = (currentStep: string | undefined): boolean =>
  (currentStep && currentStep === WorkflowStepEnum.VALIDATION) as boolean;

export const isValidated = (currentStep?: WorkflowStepEnum): boolean =>
  (currentStep &&
    !Object.values(WorkFlowStepPreModelingEnum).includes(
      currentStep as WorkFlowStepPreModelingEnum
    )) as boolean;

export const isModelingStep = (currentStep: string): boolean =>
  (currentStep &&
    (currentStep === WorkflowStepEnum.MODELING ||
      currentStep === WorkflowStepEnum.MODELING_ANALYZE ||
      currentStep === WorkflowStepEnum.MODELING_PREPARE ||
      currentStep === WorkflowStepEnum.MODELING_REGISTER ||
      currentStep === WorkflowStepEnum.MODELING_DESIGN ||
      currentStep === WorkflowStepEnum.MODELING_EXPORT)) as boolean;

export const isModeled = (currentStep?: WorkflowStepEnum): boolean =>
  (currentStep &&
    [
      WorkflowStepEnum.MANUFACTURING,
      WorkflowStepEnum.CONTROL,
      WorkflowStepEnum.ASSEMBLY,
      WorkflowStepEnum.SHIPPED,
      WorkflowStepEnum.DELIVERY,
      WorkflowStepEnum.DELIVERED
    ].includes(currentStep as WorkflowPostModelingStepEnum)) as boolean;

export const isDeliveryStep = (currentStep: string | undefined): boolean =>
  (currentStep && currentStep === WorkflowStepEnum.DELIVERY) as boolean;

export const hasDeliveryStarted = (currentStep: WorkflowStepEnum): boolean => {
  return [WorkflowStepEnum.DELIVERY, WorkflowStepEnum.SHIPPED, WorkflowStepEnum.DELIVERED].includes(
    currentStep as unknown as WorkflowPostModelingStepEnum
  );
};

export const isAutomaticModelisationStep = (currentStep: WorkflowStepEnum): boolean =>
  currentStep &&
  (currentStep === WorkflowStepEnum.MODELING ||
    currentStep === WorkflowStepEnum.MODELING_ANALYZE ||
    currentStep === WorkflowStepEnum.MODELING_REGISTER ||
    currentStep === WorkflowStepEnum.MODELING_EXPORT);

export const canChangeOrderStep = (currentStep?: string): boolean =>
  (currentStep &&
    !isSubmittingStep(currentStep) &&
    !isValidationStep(currentStep) &&
    !isDeliveryStep(currentStep)) as boolean;

/**
 * Find the most advanced manufacturing step that has been reached by ALL components of all products of the manufacturing order
 * Examples:
 *   - 1 component is TO_DESIGN, all others are MANUFACTURED -> return TO_DESIGN
 *   - 1 component is DESIGNED, all others are MANUFACTURED -> return DESIGNED
 *   - 1 component is DESIGNED, all others are TO_MANUFACTURE -> return DESIGNED
 *   - 1 component is TO_DESIGN, all others are MANUFACTURED -> return TO_DESIGN
 * @param manufacturingOrder
 */
export const getMostAdvancedCommonManufacturingStep = (
  manufacturingOrder: ManufacturingOrder
): WorkflowManufacturingStepEnum => {
  const allComponentsSteps = manufacturingOrder.products
    .map((product) => product.components.map((component) => component.manufacturingStep))
    .flat();
  let mostAdvancedStep: WorkflowManufacturingStepEnum | undefined = undefined;

  // We want the most advanced step that has been reach by ALL components
  // Iterate through WorkflowManufacturingStepEnum from the least advanced step to the most advanced
  // If at least one of the components is at this step : this is it
  Object.values(WorkflowManufacturingStepEnum).forEach((step) => {
    const componentsInThisStep = allComponentsSteps.filter(
      (componentStep) => componentStep === step
    );
    if (!mostAdvancedStep && componentsInThisStep.length > 0) {
      mostAdvancedStep = step;
    }
  });

  return mostAdvancedStep ?? WorkflowManufacturingStepEnum.TO_DESIGN;
};

export const getItemComponentAttribute = (
  item: OrderItem,
  componentType: ComponentType
): OrderItemComponent | undefined => {
  return item?.itemComponents?.find((itemComponent) => {
    return itemComponent.componentType === componentType;
  });
};

export const getMaterialColor = (material: MaterialEnum) => {
  return Object.keys(MaterialColorEnum).includes(material)
    ? ColorPropsEnum[material.replace('-', '_') as keyof typeof ColorPropsEnum]
    : ColorPropsEnum.WHITE;
};

export const getCircleCadCompliantProducts = (
  order: ManufacturingOrder
): ManufacturingOrderProduct[] => {
  return order.products?.filter((product) => product.isCompliantCircleCad);
};

export const isManufacturingOrderOneDesignCompliant = (
  order: ManufacturingOrder
): boolean | undefined => {
  if (!order) {
    return false;
  }
  return order.products.length === 1 && order.products[0].isOneDesign;
};

export const isOrderOneDesignCompliant = (order: Order): boolean | undefined => {
  return order?.items?.length === 1 && order.items[0].product.isOneDesign;
};

// is the order has all products compliants circle cad
export const isManufacturingOrderFullCircleCadCompliant = (
  manufacturingOrder: ManufacturingOrder
): boolean => {
  const circleCadCompliantProducts = getCircleCadCompliantProducts(manufacturingOrder);
  return circleCadCompliantProducts?.length === manufacturingOrder.products?.length;
};

// is the order has products compliants and not compliants circle cad
export const isPartiallyCircleCadCompliant = (manufacturingOrder: ManufacturingOrder): boolean => {
  const circleCadCompliantProducts = getCircleCadCompliantProducts(manufacturingOrder);
  return (
    circleCadCompliantProducts.length > 0 &&
    circleCadCompliantProducts.length < manufacturingOrder.products?.length
  );
};

export const getSmallOrderNumber = (orderNumber: string) => {
  return orderNumber.slice(-3);
};
