import { useTranslation } from 'react-i18next';
import styles from './print-product-list.module.scss';
import { Chips } from '@anatoscope/circlestorybook';
import { ProductLine, SortedItemsForPrint } from './print-order';
import { Family } from '../../enum/product.enum';
import React from 'react';
import { ColorPropsEnum } from '../../enum/color.enum';

interface PrintProductListProps {
  sortedItems: SortedItemsForPrint;
}

const PrintProductList = ({ sortedItems }: PrintProductListProps) => {
  const { t } = useTranslation(['catalog']);

  return (
    <>
      {Object.keys(sortedItems).map((family) => {
        const categories = sortedItems[family as Family]?.map((categoryContent, categoryIndex) => {
          return (
            <React.Fragment key={`category_${family}_${categoryIndex}`}>
              <div key={`family_content_${family}_${categoryContent.category}`}>
                {categoryContent.items.map((item: ProductLine, itemIndex) => {
                  return (
                    <React.Fragment
                      key={`item_fragment_${family}_${categoryContent.category}_${itemIndex}`}>
                      <div
                        key={`item_${family}_${categoryContent.category}_${itemIndex}`}
                        className={styles['print-product-list__label']}>
                        {item.count > 1
                          ? item.label.replace('{{count}}', item.count.toString())
                          : item.label.replace('x{{count}}', '')}
                      </div>
                      {item?.material && (
                        <Chips
                          firstLabel={t(`material.${item?.material}`, { ns: 'component' })}
                          color={
                            ColorPropsEnum[
                              item?.material.toUpperCase() as keyof typeof ColorPropsEnum
                            ]
                          }
                          size={'s'}
                        />
                      )}
                      {item?.shade && (
                        <Chips
                          className={styles['print-product-list__shade']}
                          firstLabel={t(`shade.${item?.shade}`, { ns: 'component' })}
                          color={
                            ColorPropsEnum[item?.shade.toUpperCase() as keyof typeof ColorPropsEnum]
                          }
                          size={'s'}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </React.Fragment>
          );
        });
        return <div key={`family_container_${family}`}>{categories}</div>;
      })}
    </>
  );
};

export default PrintProductList;
