import { Family, ProductCategory } from '../../enum/product.enum';
import { OrderItem } from '../../models/order';
import { getLocalizedProperty } from '../../utils/utils';
import { getItemDentalArch, getComponentInItemByType } from '../order-manager/teeth-map/utils';
import { ComponentType, MaterialEnum } from '../../enum/component';
import { Product } from '../../models/product';
import i18next from 'i18next';

export interface ProductLine {
  label: string;
  count: number;
  material: MaterialEnum;
  shade: string | undefined;
}

export type ProductCategoryWithMisc = ProductCategory | 'MISC';

export interface SortedItemsForPrint {
  [Family.REMOV]?: CategoryContentForPrint[];
  [Family.FIXED]?: CategoryContentForPrint[];
  [Family.IMPLANT]?: CategoryContentForPrint[];
  [Family.GUARDS]?: CategoryContentForPrint[];
  [Family.OCCLUSION_RIMS]?: CategoryContentForPrint[];
}

interface CategoryContentForPrint {
  // When rendering the items list during print (the part on the left of the teeth map),
  // some categories (PROVISIONNAL, DEFINITIVE) must be rendered as chips, others must not appear.
  displayCategoryChip: boolean;
  category: ProductCategory;
  items: ProductLine[];
}

/*
 * Removes occurences of 'provisoire' or 'provisionnal' from a string, then trim it
 * @param {name} string - The string to modify.
 * @returns {string} The modified name.
 */
export const removeProvisionnalInfoFromString = (name: string): string => {
  const regex = /provisoire|provisionnal/gi;
  return name.replace(regex, '').trim();
};

/*
 * Returns a single line of product according to the data provided
 * Ex : Complet x{{count}}
 * @param {family} Family - The family of the product.
 * @param {product} string - The name of the product.
 * @param {dentalArch} string - The dental arch of the product.
 * @param {teethMaterial} string - The teeth material of the product.
 * @param {gingivaMaterial} string - The gingiva material of the product.
 * @param {shade} string - The teeth shade of the product.
 * @returns {string} The built line.
 */
export const buildProductLabel = (family: Family, product: string, dentalArch?: string): string => {
  let label = removeProvisionnalInfoFromString(product) + ' x{{count}}';
  if (family === Family.REMOV && dentalArch) {
    label += ' - ' + i18next.t(`arch.${dentalArch}`, { ns: 'component' });
  }
  return label;
};

/*
 * Returns an object containing all items sorted according to the need of the print render.
 * It needs to render items by family, then by category, as seen in SortedItemsForPrint.
 * Ex : Complet x{{count}}
 * @param {items} OrderItem[] - The items to sort.
 * @returns {SortedItemsForPrint} The sorted list of items.
 */
export const getSortedItemsToPrint = (items: OrderItem[]): SortedItemsForPrint => {
  const sortedItems: SortedItemsForPrint = {};
  items?.forEach(async (item) => {
    const family = item.product.family;
    const archPosition = getItemDentalArch(item);
    const product = item.product[getLocalizedProperty('label') as keyof Product] as string;
    const category = item.product?.category ?? 'MISC';
    const toothComponent = getComponentInItemByType(item, ComponentType.TOOTH);
    const partialToothComponent = getComponentInItemByType(item, ComponentType.PARTIAL_TOOTH);
    const teethMaterial = toothComponent?.material?.code as MaterialEnum;
    const partialToothMaterial = partialToothComponent?.material?.code as MaterialEnum;
    const partialToothMShade = partialToothComponent?.shade?.code;

    const teethShade = toothComponent?.shade?.code;
    const productLineLabel = buildProductLabel(family, product, archPosition);
    // Si la famille n'existe pas déjà dans notre objet, on l'initialise
    if (family && !(family in sortedItems)) sortedItems[family] = [];
    // Si la catégorie n'existe pas déjà dans notre objet famille, on l'initialise avec l'item courant
    const categoryIndex = sortedItems?.[family]?.findIndex(
      (sortedCategory) => sortedCategory.category === category
    );
    if (categoryIndex === -1) {
      sortedItems?.[family]?.push({
        category: category as ProductCategory,
        displayCategoryChip: family === Family.REMOV,
        items: [
          {
            label: productLineLabel,
            material: teethMaterial || partialToothMaterial,
            shade: teethShade || partialToothMShade,
            count: 1
          }
        ]
      });
    }
    // Si la catégorie existe déjà dans notre objet famille, on cherche si l'item courant y existe déjà...
    else {
      const existingItem = sortedItems[family]?.[categoryIndex!]?.items.find(
        (item) => item.label === productLineLabel
      );

      // ... si il existe déjà, on incrémente le compteur...
      if (existingItem) {
        existingItem.count += 1;
      }
      // ... sinon, on le rajoute dans la catégorie
      else {
        sortedItems[family]?.[categoryIndex!]?.items.push({
          label: productLineLabel,
          material: teethMaterial || partialToothMaterial,
          shade: teethShade || partialToothMShade,
          count: 1
        });
      }
    }
  });
  return sortedItems;
};
