import { useState } from 'react';
import {
  Chips,
  Divider,
  IconButton,
  SideBarModal,
  Skeleton,
  Text
} from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import { useGetOneUserQuery } from '../../services/users-api.services';
import { Country, LabUserRole } from '../../enum/user';
import { Establishment } from '../../models/establishment';
import styles from './lab-details.module.scss';
import { ColorPropsEnum } from '../../enum/color.enum';
import UpdateLaboratoryTagsForm from '../../pages/private/preferences-page/update-laboratory-tags-form/UpdateLaboratoyTagsForm';

type Props = {
  email: string;
};

const LabDetails = ({ email }: Props) => {
  const { t } = useTranslation(['preference']);
  const { data: user, isLoading, error } = useGetOneUserQuery(email);

  const [isOpenedTags, setIsOpenedTags] = useState(false);

  const handleSubmit = () => {
    setIsOpenedTags(false);
  };

  const displayEstablishment = (establishment: Establishment) => {
    return (
      <>
        <section className={styles['lab-detail__content__section']}>
          <header className={styles['lab-detail__content__section__header']}>
            <Text
              className={styles['lab-detail__content__section__header__title']}
              size="s"
              type="title"
              label={t('link.myLaboratory', { ns: 'common' })}></Text>
          </header>
          {isLoading ? (
            <>
              <Skeleton type="text" className="skeleton" />
              <Skeleton type="text" className="skeleton" />
              <Skeleton type="text" className="skeleton" />
              <Skeleton type="text" />
            </>
          ) : (
            !error &&
            establishment &&
            Object.keys(establishment).length > 0 && (
              <>
                <Text label={establishment.name}></Text>
                <Text label={establishment.address?.address}></Text>
                {establishment.address?.additionalAddress && (
                  <Text label={establishment.address.additionalAddress}></Text>
                )}

                <Text
                  label={
                    establishment.address?.zipCode +
                    ' ' +
                    establishment.address?.city +
                    ' ' +
                    (establishment.address?.region ?? '')
                  }></Text>
                <Text
                  label={
                    Object.values(Country).includes(establishment.address?.country as Country)
                      ? t(`countries.${establishment.address?.country?.toLowerCase()}`, {
                          ns: 'common'
                        })
                      : ''
                  }></Text>
              </>
            )
          )}
        </section>
        <section className={styles['lab-detail__content__divider']}>
          <Divider />
        </section>
      </>
    );
  };
  const displayLaboratoryTags = (establishment: Establishment) => {
    return (
      <section className={styles['lab-detail__content__section']}>
        <header className={styles['lab-detail__content__section__header']}>
          <Text
            className={styles['lab-detail__content__section__header__title']}
            size="s"
            type="title"
            label={t('labTags')}></Text>
          {user?.role === LabUserRole.SUPERVISOR && (
            <IconButton
              color={ColorPropsEnum.PRIMARY}
              iconSize={'sm'}
              faIconClass="fa-pen-to-square"
              onClick={() => setIsOpenedTags(true)}
              radius="full"
              data-cy="editTagsButton"
            />
          )}
        </header>
        {isLoading ? (
          <Skeleton type="text" className="skeleton" />
        ) : (
          !error && (
            <div className={styles['lab-detail__content__tags']}>
              {establishment?.tags && establishment.tags.length > 0
                ? establishment.tags.map((tag) => (
                    <div key={tag}>
                      <Chips
                        key={tag}
                        className={styles['lab-detail__content__tags__chips']}
                        color={ColorPropsEnum.PURPLE_LIGHT}
                        firstLabel={tag}
                      />
                    </div>
                  ))
                : t('noTags')}
            </div>
          )
        )}
        {user?.role === LabUserRole.SUPERVISOR && (
          <SideBarModal
            title={t('editLaboratoryTagsForm.title')}
            isOpened={isOpenedTags}
            onClose={() => setIsOpenedTags(false)}>
            <UpdateLaboratoryTagsForm
              onSubmitUpdateTagsCallback={handleSubmit}
              laboratory={establishment}
            />
          </SideBarModal>
        )}
      </section>
    );
  };

  return (
    <div className={styles['lab-detail__content']}>
      {user?.laboratory && displayEstablishment(user.laboratory)}
      {user?.laboratory && displayLaboratoryTags(user.laboratory)}
    </div>
  );
};

export default LabDetails;
