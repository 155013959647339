import i18next from 'i18next';
import { MenuEntry } from '../../models/datagrid';
import { Order } from '../../models/order';
import { canChangeOrderStep, isAutomaticModelisationStep } from '../../utils/order.utils';
import { WorkflowPostModelingStepEnum } from '../../enum/workflow-step';
import { ManufacturingOrder } from '../../models/manufacturing-order';
import { ManufacturingComponentView } from '../../models/component';

export const editAction: MenuEntry = {
  label: i18next.t('action.edit', { ns: 'common' }),
  disabled: true,
  type: 'button',
  onClick: () => {
    console.log('Test');
  }
};

export const printAction = (data: Order, callback: (data: Order) => void): MenuEntry => {
  return {
    id: 'print',
    disabled: !data.validationDate,
    label: i18next.t('action.print', { ns: 'common' }),
    type: 'button',
    onClick: () => {
      callback(data);
    }
  };
};

export const changeStepAction = (data: Order, callback: (data: Order) => void): MenuEntry => {
  return {
    id: 'changeStep',
    disabled: !canChangeOrderStep(data.currentStep),
    label: i18next.t('changeStepModal.action', { ns: 'dashboard' }),
    type: 'button',
    onClick: () => {
      callback(data);
    }
  };
};

export const toggleManufactureAction = <T extends Order | ManufacturingOrder>(
  data: T,
  callback: (data: T) => Promise<void>
): MenuEntry => {
  return {
    id: 'changeToManufacture',
    disabled: Object.values(WorkflowPostModelingStepEnum).includes(
      data.currentStep as WorkflowPostModelingStepEnum
    ),
    label: data.toManufacture
      ? i18next.t('action.setNotToBeManufactured', { ns: 'dashboard' })
      : i18next.t('action.setToBeManufactured', { ns: 'dashboard' }),
    type: 'button',
    onClick: () => {
      callback(data);
    }
  };
};

export const deleteAction: MenuEntry = {
  label: i18next.t('action.delete', { ns: 'common' }),
  disabled: true,
  type: 'button',
  onClick: () => {
    console.log('Test');
  }
};

export const changeTagsAction = (data: Order, callback: (data: Order) => void): MenuEntry => {
  return {
    label: i18next.t('updateTagsModal.action', { ns: 'dashboard' }),
    type: 'button',
    onClick: () => {
      callback(data);
    }
  };
};

export const assignOrderAction = (data: Order, callback: (data: Order) => void): MenuEntry => {
  return {
    label: i18next.t('assignOrderModal.action', { ns: 'dashboard' }),
    type: 'button',
    onClick: () => {
      callback(data);
    }
  };
};

export const commentsOrderAction = <T extends Order | ManufacturingOrder>(
  data: T,
  callback: (data: T) => void
): MenuEntry => {
  return {
    label: i18next.t('comments.action', { ns: 'comment' }),
    type: 'button',
    onClick: () => {
      callback(data);
    }
  };
};

export const unAssignOrderAction = (
  data: Order,
  callback: (data: Order) => Promise<void>
): MenuEntry => {
  return {
    label: i18next.t('action.unassign', { ns: 'dashboard' }),
    type: 'button',
    onClick: () => {
      callback(data);
    }
  };
};

export const assignManufacturingOrderAction = <
  T extends ManufacturingOrder | ManufacturingComponentView
>(
  data: T,
  callback: (data: T) => void
): MenuEntry => {
  return {
    label: i18next.t('assignOrderModal.action', { ns: 'dashboard' }),
    type: 'button',
    disabled: isAutomaticModelisationStep(data.currentStep),
    onClick: () => {
      callback(data);
    }
  };
};

export const unAssignManufacturingOrderAction = <
  T extends ManufacturingOrder | ManufacturingComponentView
>(
  data: T,
  callback: (data: T) => Promise<void>
): MenuEntry => {
  return {
    label: i18next.t('action.unassign', { ns: 'dashboard' }),
    type: 'button',
    onClick: () => {
      callback(data);
    }
  };
};

export const setMachiningAction = (
  data: ManufacturingComponentView,
  callback: (data: ManufacturingComponentView) => void
): MenuEntry => {
  return {
    label: i18next.t('action.setMachining', { ns: 'dashboard' }),
    type: 'button',
    onClick: () => {
      callback(data);
    }
  };
};

export const setPrintAction = (
  data: ManufacturingComponentView,
  callback: (data: ManufacturingComponentView) => void
): MenuEntry => {
  return {
    label: i18next.t('action.setPrint', { ns: 'dashboard' }),
    type: 'button',
    onClick: () => {
      callback(data);
    }
  };
};
