import { api } from './api';
import {
  HistoryList,
  Order,
  OrderFile,
  OrderForCreation,
  OrderForUpdate,
  OrderItem,
  OrderItemForCreation,
  OrdersIndicators,
  RefuseOrder,
  ValidateOrder
} from '../models/order';
import { Params, ServicePaginatedResponse } from '../models/datagrid';
import { FileLabelEnum } from '../enum/file-label';

export const ordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOneOrder: builder.query<Order, string>({
      query: (orderNumber: string) => ({
        url: `/orders/${orderNumber}`,
        method: 'GET'
      }),
      providesTags: ['OneOrder']
    }),
    getOrders: builder.query<ServicePaginatedResponse, Params>({
      query: (arg: Params) => {
        const { page, limit, filters, sort } = arg;
        // Always filter orders that are deleted or deleted items in orders
        return `/orders?page=${page}&filter.deletedAt=$null&filter.items.deletedAt=$null&limit=${limit}${filters}${sort}`;
      }
    }),
    createOrder: builder.mutation<Order, OrderForCreation>({
      query: (orderToCreate: OrderForCreation) => ({
        url: `/orders`,
        method: 'POST',
        body: orderToCreate
      })
    }),
    createOrderItem: builder.mutation<
      OrderItem,
      { orderNumber: string; item: OrderItemForCreation }
    >({
      query: (arg: { orderNumber: string; item: OrderItemForCreation }) => {
        const { orderNumber, item } = arg;
        return {
          url: `/orders/${orderNumber}/items`,
          method: 'POST',
          body: item
        };
      },
      invalidatesTags: ['Indicators', 'OneOrder', 'History']
    }),
    patchOrder: builder.mutation<Order, OrderForUpdate>({
      query: ({ orderNumber, ...patch }: { orderNumber: string; patch: OrderForUpdate }) => ({
        url: `/orders/${orderNumber}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['Indicators', 'OneOrder', 'History']
    }),
    validateOrder: builder.mutation<Order, ValidateOrder>({
      query: ({ orderNumber, ...validateOrderBody }) => ({
        url: `/orders/${orderNumber}/validate`,
        method: 'POST',
        body: validateOrderBody
      }),
      invalidatesTags: ['Indicators', 'History']
    }),
    refuseOrder: builder.mutation<Order, RefuseOrder>({
      query: ({ orderNumber, ...refuseOrderBody }) => ({
        url: `/orders/${orderNumber}/validate`,
        method: 'POST',
        body: refuseOrderBody
      }),
      invalidatesTags: ['Indicators', 'History']
    }),
    submitOrder: builder.mutation<Order, string>({
      query: (orderNumber: string) => ({
        url: `/orders/${orderNumber}/submit`,
        method: 'POST'
      }),
      invalidatesTags: ['Indicators', 'History']
    }),
    getOrdersIndicators: builder.query<OrdersIndicators, string>({
      query: () => ({
        url: '/orders/indicators',
        method: 'GET'
      }),
      providesTags: ['Indicators']
    }),
    uploadPatientFileToOrder: builder.mutation<
      { id: number; uploadUrl: string; uploadUrlS3: string },
      { orderNumber: string; file: OrderFile }
    >({
      query: ({ orderNumber, file }: { orderNumber: string; file: OrderFile }) => ({
        url: `/orders/${orderNumber}/patient-files`,
        method: 'POST',
        body: file
      }),
      invalidatesTags: ['OneOrder', 'History']
    }),
    deleteOrderPatientFile: builder.mutation<
      void,
      { orderNumber: string; file: Partial<OrderFile> }
    >({
      query: ({ orderNumber, file }: { orderNumber: string; file: Partial<OrderFile> }) => ({
        url: `/orders/${orderNumber}/patient-files/${file.id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['OneOrder', 'History']
    }),
    uploadToStorage: builder.mutation<object, { url: string; file: File }>({
      query: ({ url, file }: { url: string; file: File }) => ({
        url: url,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        body: file
      })
    }),
    predictPatientFileLabel: builder.mutation<FileLabelEnum, { fileName: string }>({
      query: ({ fileName }: { fileName: string }) => ({
        url: `/labels/predict`,
        method: 'POST',
        params: { filename: fileName },
        body: {},
        responseHandler: 'text'
      })
    }),
    labelOrderPatientFile: builder.mutation<OrderFile, { orderNumber: string; file: OrderFile }>({
      query: ({ orderNumber, file }: { orderNumber: string; file: OrderFile }) => ({
        url: `/orders/${orderNumber}/patient-files/${file.id}`,
        method: 'PATCH',
        body: { fileLabel: file.fileLabel }
      }),
      invalidatesTags: ['OneOrder', 'History'] // invalidate patient files
    }),
    addCommentToOrder: builder.mutation<Order, { orderNumber: string; comment: string }>({
      query: ({ orderNumber, comment }: { orderNumber: string; comment: string }) => ({
        url: `/orders/${orderNumber}/comments`,
        method: 'POST',
        body: { comment }
      }),
      invalidatesTags: ['OneOrder', 'History']
    }),
    getHistory: builder.query<HistoryList, string>({
      query: (orderNumber: string) => {
        return `/orders/${orderNumber}/history`;
      },
      providesTags: ['History']
    })
  })
});

export const {
  useLazyGetOrdersQuery,
  useDeleteOrderPatientFileMutation,
  useGetOneOrderQuery,
  useLazyGetOneOrderQuery,
  useCreateOrderMutation,
  useCreateOrderItemMutation,
  usePatchOrderMutation,
  useValidateOrderMutation,
  useRefuseOrderMutation,
  useSubmitOrderMutation,
  useGetOrdersIndicatorsQuery,
  useUploadPatientFileToOrderMutation,
  useUploadToStorageMutation,
  useLabelOrderPatientFileMutation,
  usePredictPatientFileLabelMutation,
  useAddCommentToOrderMutation,
  useGetHistoryQuery
} = ordersApi;
