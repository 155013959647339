import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import orderStyles from '../order-form.module.scss';
import useForm from '../../../utils/useForm';
import {
  Box,
  Button,
  ButtonsGroup,
  SideBarModal,
  TeethMap,
  Text
} from '@anatoscope/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  currentItemSelector,
  orderErrorSelector,
  orderItemsSelector
} from '../../../store/orders/orders.selectors';
import { ordersActions } from '../../../store/orders/orders.reducer';
import { useGetConnectedUserQuery } from '../../../services/users-api.services';
import { TeethSelectionMode } from '../../../enum/product.enum';
import { DentalNotation } from '../../../enum/user';
import { ColorPropsEnum } from '../../../enum/color.enum';
import {
  cursorsSelector,
  lineAndNumberColorsSelector,
  mapContextSelector,
  mapSvgLayerSelector,
  teethBubblesSelector,
  teethShadesSelector,
  zoneLinkPropsSelector
} from '../../../store/map/map.selectors';
import { mapActions } from '../../../store/map/map.reducer';
import { PositionKeyString } from '../../../models/position';
import { NotAllowedCursors, SelectionContextEnum } from '../../../enum/map.enum';
import { getFamilyColor } from '../../order-manager/teeth-map/utils';
import AddProductModal from './add-product-modal/AddProductModal';
import { MapClickPayload } from '../../../models/map';
import { productCustomizationSelector } from '../../../store/product-customization/productCustomization.selectors';
import { productCustomizationActions } from '../../../store/product-customization/productCustomization.reducer.tsx';
import { ButtonsGroupOptions } from '../../../models/form.tsx';
import { ComponentType } from '../../../enum/component.ts';
import { useGetCommonTypesQuery } from '../../../services/common-types-api.services.ts';
import { Structure } from '../../../models/common-types';
import { publicImagesUrl } from '../../../utils/utils';

type Props = {
  nextCallback: () => void;
  previousCallback: () => void;
};

const ProsthesisForm = ({ nextCallback, previousCallback }: Props) => {
  const { t } = useTranslation(['order']);
  const dispatch = useAppDispatch();

  const { data: connectedUser } = useGetConnectedUserQuery();
  const { data: commonTypes } = useGetCommonTypesQuery();

  const orderItems = useAppSelector(orderItemsSelector);
  const currentItem = useAppSelector(currentItemSelector);
  const error = useAppSelector(orderErrorSelector);
  const zoneLinkProps = useAppSelector(zoneLinkPropsSelector);
  const mapSvgLayers = useAppSelector(mapSvgLayerSelector); // EXTRACT, INLAY, STUMP ...
  const mapContext = useAppSelector(mapContextSelector);
  const cursors = useAppSelector(cursorsSelector);
  const lineAndNumberColors = useAppSelector(lineAndNumberColorsSelector);
  const teethShades = useAppSelector(teethShadesSelector);
  const activeCustomizations = useAppSelector(productCustomizationSelector);
  const bubbles = useAppSelector(teethBubblesSelector);

  const [sideBarOpened, setSideBarOpened] = useState(false);
  const [teethStructure, setTeethStructure] = useState<Structure>();

  const isStructureToolDisplayed = activeCustomizations?.displayStructureTool;

  const submit = (): void => {
    if (currentItem) {
      dispatch(ordersActions.setError(t('createOrder.error.endCurrentProduct')));
      return;
    }
    if (!orderItems.length) {
      dispatch(ordersActions.setError(t('createOrder.error.emptyItem')));
      return;
    }
    dispatch(ordersActions.setError(undefined));
    nextCallback();
  };

  const handlePrevious = (): void => {
    if (currentItem) {
      dispatch(ordersActions.setError(t('createOrder.error.endCurrentProduct')));
      return;
    }
    dispatch(ordersActions.setError(undefined));
    previousCallback();
  };

  useEffect(() => {
    if (!isStructureToolDisplayed) {
      setTeethStructure(undefined);
      dispatch(productCustomizationActions.setTeethStructure(undefined));
      dispatch(mapActions.computeProductSelectionTooth());
      dispatch(mapActions.resetStructurePositionsSelectable());
    }
  }, [isStructureToolDisplayed]);

  const optionsStructure = (): Array<ButtonsGroupOptions> => {
    if (currentItem?.product?.components) {
      return (
        currentItem.product.components
          .find((component) => component.componentType === ComponentType.TOOTH)
          ?.structures?.map((structure) => {
            return {
              label: t(`structure.${structure.code}`, {
                ns: 'component'
              }),
              value: structure.id.toString(),
              url: `${publicImagesUrl}structures/${structure.code}.svg`
            };
          })
          .filter((structure) => !!structure) || []
      );
    }
    return [];
  };

  const { handleSubmit } = useForm({}, submit);

  const handleSelectStructure = (id: string): void => {
    const teethStructure = commonTypes?.structures.find((structure) => structure.id === +id);
    if (teethStructure && currentItem) {
      setTeethStructure(teethStructure);
      dispatch(productCustomizationActions.setTeethStructure(teethStructure.code));
      dispatch(mapActions.setStructurePositionsSelectable());
    }
  };
  /**
   * Function to select a tooth.
   *
   * @param {PositionKeyString} selectedTooth - The tooth to be selected.
   */
  const handleSelectPosition = (selectedTooth: PositionKeyString): void => {
    if (!currentItem) {
      return;
    }
    const mapClick: MapClickPayload = {
      notation: selectedTooth,
      teethShade: activeCustomizations.teethShade,
      teethStructure: teethStructure,
      gingivaShade: activeCustomizations.gingivaShade,
      frameMaterial: activeCustomizations.frameMaterial,
      familyColor: getFamilyColor(currentItem.product.family),
      stumpMode: currentItem.product?.stumpMode,
      componentTypes: currentItem.itemComponents!.map((item) => item.componentType),
      svgLayers: currentItem.product
        .components!.map((item) => item.svgLayer)
        .filter((item) => item) as string[]
    };

    dispatch(ordersActions.setError(undefined));
    switch (mapContext?.teethSelectionMode) {
      case TeethSelectionMode.SINGLE_TOOTH:
        dispatch(mapActions.selectSinglePosition(mapClick));
        break;
      case TeethSelectionMode.STRUCTURE_TOOTH:
        dispatch(mapActions.selectStructurePosition(mapClick));
        break;
      case TeethSelectionMode.SINGLE_RANGE:
        // 1. click occurred on tooth map && context is not defined => it's the first click
        if (!mapContext?.userAction) {
          dispatch(mapActions.startSingleRange(mapClick));
        }
        // 2. click occurred on tooth map && context is range-started => it's the second click
        if (mapContext?.userAction === SelectionContextEnum.RANGE_STARTED) {
          dispatch(mapActions.closeSingleRange(mapClick));
          if (!currentItem?.product?.productRule?.allowSaneTeethInArch) {
            dispatch(mapActions.removeSaneTeeth(selectedTooth));
          }
        }
        break;
      case TeethSelectionMode.MULTI_RANGE:
        // 1. click occurred on tooth map && context is not defined (first click) or context is zone-ended => it's the first click - start new zone
        if (!mapContext?.userAction || mapContext?.userAction === SelectionContextEnum.ZONE_ENDED) {
          dispatch(mapActions.startZone(mapClick));
        }

        // 2. click occurred on tooth map && context is zone-started => it's the second click - end zone
        if (mapContext?.userAction === SelectionContextEnum.ZONE_STARTED) {
          dispatch(mapActions.endZone(mapClick));
        }
        // Set zone link positions to draw them
        dispatch(mapActions.setZoneLinkPositions());

        break;
    }

    // update selection teeth for cursors
    dispatch(mapActions.computeProductSelectionTooth());
  };

  const handleCloseSidebar = (): void => {
    setSideBarOpened(false);
  };

  const handleCancelSidebar = (): void => {
    setSideBarOpened(false);
    dispatch(ordersActions.setCurrentItem(undefined));
  };

  return (
    <Box color={ColorPropsEnum.WHITE} className={orderStyles['order-form__box']}>
      <SideBarModal
        title={t('createOrder.summary.addProduct')}
        isOpened={sideBarOpened}
        closeOnOutsideClick={false}
        onClose={() => setSideBarOpened(false)}>
        <AddProductModal
          onCloseCallback={handleCloseSidebar}
          onCancelCallback={handleCancelSidebar}
        />
      </SideBarModal>
      <form onSubmit={handleSubmit} className={orderStyles['order-form__box__form']}>
        <div className={orderStyles['order-form__box__form__content']}>
          <div className={orderStyles['order-form__box__form__content__mouth']}>
            <div className={orderStyles['order-form__box__form__content__mouth__content']}>
              <Box className={orderStyles['order-form__box__intro']}>
                {!error && currentItem?.product?.teethMode && (
                  <>
                    <Text
                      color={ColorPropsEnum.WARNING}
                      bold={true}
                      label={t(`createOrder.prosthesis.${mapContext?.teethSelectionMode}`)}
                    />
                    {isStructureToolDisplayed && (
                      <ButtonsGroup
                        data-cy="teethStructure"
                        id="teethStructure"
                        displayedInRow={true}
                        onClick={(id: string) => {
                          handleSelectStructure(id);
                        }}
                        selectedValue={teethStructure?.id.toString()}
                        options={optionsStructure()}
                      />
                    )}
                  </>
                )}
                {error && <Text label={error} color={ColorPropsEnum.DANGER} />}
                {!error && !currentItem && orderItems?.length !== 0 && (
                  <Text label={t('createOrder.prosthesis.nextStep')} />
                )}
                {!error && !currentItem && orderItems?.length === 0 && (
                  <Text label={t('createOrder.prosthesis.emptyItem')} />
                )}
              </Box>
              <TeethMap
                className={orderStyles['order-form__box__form__content__mouth__content__teeth-map']}
                onClick={(tooth: PositionKeyString) => handleSelectPosition(tooth)}
                patientMouth={{ ...mapSvgLayers }}
                cursors={currentItem ? cursors : NotAllowedCursors}
                notation={connectedUser ? connectedUser?.dentalNotation : DentalNotation.ISO}
                teethShades={teethShades}
                zoneLinkProps={zoneLinkProps}
                lineAndNumberColors={lineAndNumberColors}
                displayShadows={false}
                isStrokeTeeth={true}
                bubbles={bubbles}
              />
            </div>
          </div>
        </div>
        <div className="form__submit-button form__submit-button--double">
          <Button
            label={t('action.previous', { ns: 'common' })}
            category="tertiary"
            onClick={handlePrevious}
            iconLeft="fa-chevron-left"
            data-cy="previousButton"
          />
          <Button
            label={t('action.next', { ns: 'common' })}
            type="submit"
            category="secondary"
            iconRight="fa-chevron-right"
            data-cy="nextButton"
          />
        </div>
      </form>
    </Box>
  );
};
export default ProsthesisForm;
