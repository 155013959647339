import { useEffect } from 'react';
import styles from './assign-form.module.scss';
import {
  AttentionBox,
  Box,
  Button,
  Dropdown,
  HorizontalBarChart,
  Skeleton
} from '@anatoscope/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { attentionBoxErrorSelector } from '../../store/feedback/feedback.selector';
import { getMessageError, getNameFromEmail } from '../../utils/utils';
import { useGetLabUsersQuery } from '../../services/users-api.services';
import useForm from '../../utils/useForm';
import {
  useGetOrdersIndicatorsQuery,
  usePatchOrderMutation
} from '../../services/orders-api.services';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { LabUser } from '../../models/user';
import { getLabUsersListForDropdownSelector } from '../../store/users/users.selectors';
import { usePatchManufacturingOrderMutation } from '../../services/manufacturing-orders-api.services';
import { ColorPropsEnum } from '../../enum/color.enum';
import { ToastType } from '../../enum/feedback';
import { usePatchComponentMutation } from '../../services/components-api.services';

type props = {
  onSubmitAssignManufacturingOrderCallback: () => void;
  orderNumber?: string;
  productId?: number;
  componentId?: number;
  mode?: string;
};

const AssignForm = ({
  onSubmitAssignManufacturingOrderCallback,
  orderNumber,
  productId,
  componentId,
  mode
}: props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const attentionBoxError = useAppSelector(attentionBoxErrorSelector);
  const labUsersListForDropdown = useAppSelector(getLabUsersListForDropdownSelector());

  const {
    data: ordersIndicators,
    isLoading: isLoadingOrderIndicators,
    isError: isErrorIndicators
  } = useGetOrdersIndicatorsQuery('OrdersIndicators');

  const [
    patchManufacturingOrder,
    {
      isLoading: isLoadingPatchManufacturingOrder,
      isSuccess: isSuccessPatchManufacturingOrder,
      error: patchManufacturingOrderError,
      reset: patchManufacturingOrderReset
    }
  ] = usePatchManufacturingOrderMutation();
  const [patchOrder, { isLoading: isLoadingPatchOrder, isSuccess: isSuccessPatchOrder }] =
    usePatchOrderMutation();
  const [
    patchComponent,
    {
      isLoading: isLoadingPatchComponent,
      isSuccess: isSuccessPatchComponent,
      error: patchComponentError,
      reset: patchComponentReset
    }
  ] = usePatchComponentMutation();

  const { data: labUsers, isLoading: isLoadingLabUsers } = useGetLabUsersQuery();

  const assigneeMandatory = i18next.t('assignOrderModal.assigneeMandatory', { ns: 'production' });

  const assigneesChart =
    labUsers?.length && !isErrorIndicators
      ? labUsers.map((user: LabUser) => {
          const assignee = ordersIndicators?.assignees.find(
            (assignee) => assignee.assigneeEmail === user.email
          );
          return {
            id: user.id,
            label: `${user.firstName} ${user.lastName}`,
            value: assignee ? assignee.orderCount : 0
          };
        })
      : [];

  const onSubmit = async () => {
    if (orderNumber) {
      patchManufacturingOrder({
        orderNumber: orderNumber,
        assigneeEmail: values.assignee as string,
        assigneeName: getNameFromEmail(labUsers, values.assignee as string)
      });
    }
    if (productId && componentId) {
      patchComponent({
        productId: productId,
        id: componentId,
        assigneeEmail: values.assignee as string,
        assigneeName: getNameFromEmail(labUsers, values.assignee as string)
      });
    }
  };

  useEffect(() => {
    if (isSuccessPatchManufacturingOrder) {
      patchOrder({
        orderNumber: orderNumber,
        assigneeEmail: values.assignee as string,
        assigneeName: getNameFromEmail(labUsers, values.assignee as string)
      });
    }
  }, [isSuccessPatchManufacturingOrder]);

  useEffect(() => {
    if (isSuccessPatchOrder || isSuccessPatchComponent) {
      dispatch(
        feedbackActions.setToast({
          message:
            mode === 'manufacturing'
              ? t('assignComponentManufacturingModal.successAssign', { ns: 'production' })
              : t('assignOrderModal.success', { ns: 'production' }),
          type: ToastType.SUCCESS
        })
      );
      onSubmitAssignManufacturingOrderCallback();
    }
  }, [isSuccessPatchOrder, isSuccessPatchComponent]);

  const { values, errors, handleSubmit, handleSelect } = useForm(
    {
      assignee: null
    },
    onSubmit
  );

  return (
    <>
      {(patchManufacturingOrderError || patchComponentError) && attentionBoxError && (
        <AttentionBox
          mode={ColorPropsEnum.DANGER}
          text={getMessageError(patchManufacturingOrderError || patchComponentError)}
          className={styles['assign-manufacturing-order-form__attention-box']}
          onClose={() =>
            patchManufacturingOrderError ? patchManufacturingOrderReset() : patchComponentReset()
          }
        />
      )}

      {isLoadingOrderIndicators || isLoadingLabUsers ? (
        <>
          <Skeleton type="text" className="skeleton" width="90%" />
          <Skeleton type="text" className="skeleton" width="90%" />
          <Skeleton type="text" className="skeleton" width="90%" />
        </>
      ) : (
        mode !== 'manufacturing' && (
          <HorizontalBarChart
            data={assigneesChart}
            title={t('assignOrderModal.chart.title', { ns: 'production' })}
            className={styles['assign-manufacturing-order-form__chart']}
          />
        )
      )}
      <Box
        data-cy="assign-form"
        color={ColorPropsEnum.WHITE}
        className={styles['assign-manufacturing-order-form__form']}>
        <form onSubmit={handleSubmit}>
          <Dropdown
            label={t('assignOrderModal.label', { ns: 'production' })}
            data={labUsersListForDropdown}
            value={values?.assignee}
            size="m"
            placeholder={t('assignOrderModal.placeholder', { ns: 'production' })}
            onChange={(newValue: string) => handleSelect(newValue, 'assignee')}
            helperText={errors?.assignee && assigneeMandatory}
            variant={errors?.assignee ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            isLoading={isLoadingLabUsers}
            isSearchable={true}
          />
          <div className="form__submit-button form__submit-button--right">
            <Button
              label={t('assignOrderModal.action', { ns: 'production' })}
              isLoading={
                isLoadingPatchManufacturingOrder || isLoadingPatchOrder || isLoadingPatchComponent
              }
              type="submit"
            />
          </div>
        </form>
      </Box>
    </>
  );
};

export default AssignForm;
