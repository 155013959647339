import { useEffect } from 'react';
import '@inovua/reactdatagrid-community/index.css';
import {
  faBackward,
  faBan,
  faTrashCan,
  faChartSimple,
  faChevronLeft,
  faChevronRight,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faFileExcel,
  faGauge,
  faUsers,
  faPenToSquare,
  faPlus,
  faReceipt,
  faRedo,
  faTableList,
  faTooth,
  faUpload,
  faXmark,
  faPrint,
  faRefresh,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { faComment, faCopy } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './store/hooks';
import RoutesLab from './RoutesLab';
import { authActions } from './store/auth/auth.reducers';
import { getAuthState, isLabUser } from './services/firebase.services';
import { User } from 'firebase/auth';
import { isAuthenticatedSelector } from './store/auth/auth.selectors';
import { history } from './models/history';

library.add(
  faBan,
  faTrashCan,
  faEllipsisVertical,
  faTableList,
  faChartSimple,
  faTooth,
  faReceipt,
  faRedo,
  faGauge,
  faUsers,
  faPlus,
  faXmark,
  faChevronRight,
  faChevronLeft,
  faPenToSquare,
  faUpload,
  faDownload,
  faEnvelope,
  faFileExcel,
  faBackward,
  faCopy,
  faPrint,
  faComment,
  faRefresh,
  faExclamationTriangle
);

const App = () => {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Control user authentication with firebase (refresh, browser tabs...)
    const saveUserState = async (user: User | null) => {
      let isAuthorized = false;
      // Reducers don't like await. They are so impatient.
      if (user) {
        isAuthorized = await isLabUser();
      }
      // Redux loves so much simple object or complex string :)
      dispatch(
        authActions.saveUserState({
          firebaseUser: JSON.stringify(user),
          isAuthorized
        })
      );
    };
    getAuthState(saveUserState);
  }, []);
  return (
    <>
      <div id="portal-root"></div>
      <div id="side-bar-modal-root"></div>
      <div id="dropdown-root"></div>
      <div id="dropdown-menu-root"></div>
      <div id="tooltip-root"></div>
      <div id="dialog-root"></div>
      <div id="file-labeler-root"></div>
      {isAuthenticated !== undefined && <RoutesLab></RoutesLab>}
    </>
  );
};
export default App;
