import styles from './step-actions.module.scss';
import { ManufacturingComponentView } from '../../../../../../models/component';
import i18next from 'i18next';
import { getActionNextStepLabel, isStepBackExists, allIsSuccess } from '../manufacturing-steps';
import { Text, Button, IconButton, Tooltip, TooltipContent } from '@anatoscope/circlestorybook';
import moment from '../../../../../../moment-config';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';

interface StepActionsProps {
  component: ManufacturingComponentView;
  callbackNextStep: (data: ManufacturingComponentView) => void;
  callbackPreviousStep: (data: ManufacturingComponentView) => void;
}

const StepActions = ({ component, callbackNextStep, callbackPreviousStep }: StepActionsProps) => {
  return allIsSuccess(component.manufacturingStep) ? (
    <div className={styles['step-actions__date']}>
      <Text
        color={ColorPropsEnum.GREY}
        label={moment(component?.manufacturingDate).format('DD MMM HH:mm')}
        size="s"
      />
    </div>
  ) : (
    <div className={styles['step-actions__actions']}>
      <Button
        data-cy={`handle-step-button-${component.manufacturingStep}`}
        category="primary"
        label={getActionNextStepLabel(component.manufacturingStep)}
        size="s"
        onClick={() => callbackNextStep(component)}
      />

      <Tooltip
        className={
          !isStepBackExists(component.manufacturingStep)
            ? styles['step-actions__actions--hidden']
            : ''
        }>
        <TooltipContent className={styles['step-actions__actions--hidden']}>
          {i18next.t('tooltip.backStep', { ns: 'production' })}
        </TooltipContent>
        <IconButton
          data-cy={`previous-step-button-${component.id}`}
          className={
            !isStepBackExists(component.manufacturingStep)
              ? styles['step-actions__actions--hidden']
              : ''
          }
          faIconClass="backward"
          iconSize="sm"
          radius="full"
          onClick={() => callbackPreviousStep(component)}
        />
      </Tooltip>
    </div>
  );
};

export default StepActions;
