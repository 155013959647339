import {
  IconButton,
  Text,
  Timeline,
  TimelineItem,
  Tooltip,
  TooltipContent
} from '@anatoscope/circlestorybook';
import {
  WorkflowManufacturingStepEnum,
  WorkflowModelingStepEnum,
  WorkflowPostModelingStepEnum,
  WorkflowStepEnum
} from '../../../../../../enum/workflow-step';
import { useTranslation } from 'react-i18next';
import { faArrowsRotate, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import {
  getMostAdvancedCommonManufacturingStep,
  hasDeliveryStarted,
  isModelingStep
} from '../../../../../../utils/order.utils';
import styles from './order-detail-timeline.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { ManufacturingOrder } from '../../../../../../models/manufacturing-order';
import { Order } from '../../../../../../models/order';
import { getFormattedLocalizedMomentDate } from '../../../../../../features/order-manager/teeth-map/utils';
import { feedbackActions } from '../../../../../../store/feedback/feedback.reducer';
import i18next from 'i18next';
import { ToastType } from '../../../../../../enum/feedback';
import { useAppDispatch } from '../../../../../../store/hooks';

type FullOrderTimelineProps = {
  order: Order;
  manufacturingOrder?: ManufacturingOrder;
};
const OrderDetailTimeline = ({ order, manufacturingOrder }: FullOrderTimelineProps) => {
  const { t } = useTranslation(['orderDetail']);
  const dispatch = useAppDispatch();
  const currentStep = order.currentStep;
  const stepsToDisplay = [
    WorkflowStepEnum.SUBMITTING,
    WorkflowStepEnum.VALIDATION,
    WorkflowStepEnum.MODELING,
    WorkflowStepEnum.MANUFACTURING,
    WorkflowStepEnum.CONTROL,
    WorkflowStepEnum.DELIVERY
  ];
  let currentStepIndex = -1;
  // We only display "macro steps", which means we need to map specific modeling steps to the macro modeling step.
  const modelingSubStep = currentStep && isModelingStep(currentStep) ? currentStep : undefined;
  const activeStep = (() => {
    if (modelingSubStep) {
      return WorkflowStepEnum.MODELING;
    }

    if (currentStep && hasDeliveryStarted(currentStep)) {
      return WorkflowStepEnum.DELIVERY;
    }
    return currentStep;
  })();
  const getManufacturingStepLabel = (step: WorkflowManufacturingStepEnum): string => {
    switch (step) {
      case WorkflowManufacturingStepEnum.TO_DESIGN:
        return t('order.manufacturingStatus.design.inProgress', { ns: 'common' });
      case WorkflowManufacturingStepEnum.DESIGNED:
        return t('order.manufacturingStatus.design.success', { ns: 'common' });
      case WorkflowManufacturingStepEnum.TO_MANUFACTURE:
        return t('order.manufacturingStatus.production.inProgress', { ns: 'common' });
      case WorkflowManufacturingStepEnum.MANUFACTURED:
        return t('order.manufacturingStatus.production.success', { ns: 'common' });
    }
  };

  const getTooltipContentForCurrentStep = (currentStep: WorkflowStepEnum) => {
    switch (currentStep) {
      case WorkflowModelingStepEnum.MODELING:
        return (
          <TooltipContent>
            <div>{t(`order.steps.${order.currentStep?.toLowerCase()}`, { ns: 'common' })}</div>
          </TooltipContent>
        );
      case WorkflowStepEnum.MANUFACTURING:
        if (!manufacturingOrder) {
          return undefined;
        }
        return (
          <TooltipContent>
            <div>
              {getManufacturingStepLabel(
                getMostAdvancedCommonManufacturingStep(manufacturingOrder)
              )}
            </div>
          </TooltipContent>
        );
      case WorkflowPostModelingStepEnum.DELIVERY:
      case WorkflowPostModelingStepEnum.DELIVERED:
        if (!manufacturingOrder?.shipping) {
          return undefined;
        }
        return (
          <TooltipContent>
            <div className={styles['order-detail-timeline__item__tooltip__block']}>
              <Text
                bold={true}
                color={ColorPropsEnum.WHITE}
                label={t('tabs.detail.timeline.tooltips.delivery.date')}
              />
              <Text
                color={ColorPropsEnum.WHITE}
                label={getFormattedLocalizedMomentDate(
                  'middle',
                  manufacturingOrder.shipping.shippingDate
                )}
              />
            </div>
            <div className={styles['order-detail-timeline__item__tooltip__block']}>
              <Text
                bold={true}
                color={ColorPropsEnum.WHITE}
                label={t('tabs.detail.timeline.tooltips.delivery.number')}
              />
              <div className={styles['order-detail-timeline__item__tooltip__block__row']}>
                <Text
                  color={ColorPropsEnum.WHITE}
                  label={manufacturingOrder.shipping.deliveryLink}
                />
                <IconButton
                  color={ColorPropsEnum.WHITE}
                  iconSize={'xs'}
                  faIconClass="copy"
                  iconStyle="regular"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      manufacturingOrder.shipping?.deliveryLink as string
                    );
                    dispatch(
                      feedbackActions.setToast({
                        message: i18next.t('toast.copiedToBlipboard', {
                          orderNumber: manufacturingOrder.shipping?.deliveryLink,
                          ns: 'common'
                        }),
                        type: ToastType.SUCCESS
                      })
                    );
                  }}
                  radius="full"
                />
              </div>
            </div>
            <div className={styles['order-detail-timeline__item__tooltip__block']}>
              <Text
                bold={true}
                color={ColorPropsEnum.WHITE}
                label={t('tabs.detail.timeline.tooltips.delivery.address')}
              />
              <Text color={ColorPropsEnum.WHITE} label={manufacturingOrder.shipping.address} />
              {manufacturingOrder.shipping.additionalAddress && (
                <Text
                  color={ColorPropsEnum.WHITE}
                  label={manufacturingOrder.shipping.additionalAddress}
                />
              )}
              <Text
                color={ColorPropsEnum.WHITE}
                label={`${manufacturingOrder.shipping.zipCode} ${manufacturingOrder.shipping.city}`}
              />
              {manufacturingOrder.shipping.region && (
                <Text color={ColorPropsEnum.WHITE} label={manufacturingOrder.shipping.region} />
              )}
              <Text
                color={ColorPropsEnum.WHITE}
                label={t(`countries.${manufacturingOrder.shipping.country.toLowerCase()}`, {
                  ns: 'common'
                })}
              />
            </div>
          </TooltipContent>
        );
      default:
        return undefined;
    }
  };

  return (
    <Timeline
      timelineItemOnly={true}
      data-cy="timeline"
      className={styles['order-detail-timeline']}>
      {stepsToDisplay.map((step, index) => {
        const isActiveStep = activeStep === step;
        let tooltipContent = undefined;
        if (isActiveStep) {
          currentStepIndex = index;
          tooltipContent = getTooltipContentForCurrentStep(step);
        }
        const timelineItem = (
          <TimelineItem
            key={step}
            label={t(`tabs.detail.timeline.steps.${step}`)}
            data={{}}
            isFirstItem={index === 0}
            // Delivery & delivered share the same step here, delivery mean it's still in progress, delivered means it's done
            isInProgress={isActiveStep && currentStep !== WorkflowPostModelingStepEnum.DELIVERED}
            isSuccess={
              currentStepIndex === -1 || currentStep === WorkflowPostModelingStepEnum.DELIVERED
            }
            isInProgressIcon={faArrowsRotate}
          />
        );

        return tooltipContent !== undefined ? (
          <Tooltip
            key={`tooltip_${step}`}
            isClickable={true}
            className={styles['order-detail-timeline__item--with-tooltip']}>
            {timelineItem}
            <FontAwesomeIcon
              icon={faCircleInfo}
              color={ColorPropsEnum.GREY}
              className={styles['order-detail-timeline__item--with-tooltip__icon']}
            />
            {tooltipContent}
          </Tooltip>
        ) : (
          timelineItem
        );
      })}
    </Timeline>
  );
};

export default OrderDetailTimeline;
