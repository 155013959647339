import { useEffect, useState } from 'react';
import styles from './upload-form.module.scss';
import { AttentionBox, Box, Button } from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { attentionBoxErrorSelector } from '../../../../../../store/feedback/feedback.selector';
import { getMessageError } from '../../../../../../utils/utils';
import {
  useDeleteOrderPatientFileMutation,
  useLabelOrderPatientFileMutation,
  useLazyGetOneOrderQuery,
  usePredictPatientFileLabelMutation,
  useUploadPatientFileToOrderMutation,
  useUploadToStorageMutation
} from '../../../../../../services/orders-api.services';
import { OrderFile } from '../../../../../../models/order';
import {
  addFile,
  areSameFiles,
  displayFileInViewer,
  loadOrderFilesData,
  removeFile,
  uploadFilesToBackEnd
} from '../../../../../../features/file-manager/file.utils';
import { FileLabelEnum } from '../../../../../../enum/file-label';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { deleteFileAction } from '../../../../../../features/file-manager/menu-entry';
import { FileManagerOrientationProp } from '../../../../../../features/file-manager/file-manager.enum';
import { MenuEntry } from '../../../../../../models/datagrid';
import FileManagerContainer from '../../../../../../features/file-manager/FileManagerContainer';
import {
  isLoadingFilesSelector,
  orderFile3dToDisplaySelector,
  orderFileImageToDisplaySelector,
  orderFilesSelector,
  orderFileTextureToDisplaySelector
} from '../../../../../../store/orders/orders.selectors';
import { ordersActions } from '../../../../../../store/orders/orders.reducer';
import {
  useDownloadFileFromStorageMutation,
  useLazyGetOneDownloadableFileQuery
} from '../../../../../../services/files-api.services';

type props = {
  onSubmitCallback: () => void;
  orderNumber: string;
};

const UploadForm = ({ onSubmitCallback, orderNumber }: props) => {
  const { t } = useTranslation(['production']);
  const dispatch = useAppDispatch();

  const attentionBoxError = useAppSelector(attentionBoxErrorSelector);
  const orderFiles = useAppSelector(orderFilesSelector);
  const file3dToDisplay = useAppSelector(orderFile3dToDisplaySelector);
  const fileTextureToDisplay = useAppSelector(orderFileTextureToDisplaySelector);
  const fileImageToDisplay = useAppSelector(orderFileImageToDisplaySelector);
  const isLoadingFiles = useAppSelector(isLoadingFilesSelector);

  // RTK Query
  const [getOrder, { data: order, error: orderError }] = useLazyGetOneOrderQuery();
  const [deleteOrderFile, { error: deleteFileError, reset: resetDeleteFileError }] =
    useDeleteOrderPatientFileMutation();
  const [uploadToOrder, { error: uploadToOrderError, reset: resetUploadToOrderError }] =
    useUploadPatientFileToOrderMutation();
  const [uploadToStorage, { error: uploadToStorageError, reset: resetUploadToStorageError }] =
    useUploadToStorageMutation();
  const [
    patchFileLabel,
    { error: labelFileError, isSuccess: labelFileSuccess, reset: resetLabelFileError }
  ] = useLabelOrderPatientFileMutation();
  const [getOneDownloadableFile] = useLazyGetOneDownloadableFileQuery();
  const [downloadFromStorage] = useDownloadFileFromStorageMutation();
  const [predictLabel] = usePredictPatientFileLabelMutation();

  // UseState
  const [error, setError] = useState<object>();
  const [filesBeforePatch, setFilesBeforePatch] = useState<OrderFile[]>();

  useEffect(() => {
    if (!order || order.orderNumber !== orderNumber) {
      dispatch(ordersActions.resetOrder());
      getOrder(orderNumber);
    }
  }, [order]);

  useEffect(() => {
    if (order?.patient?.diagnostic?.patientFiles && !orderFiles?.length) {
      loadOrderFilesData(
        dispatch,
        getOneDownloadableFile,
        downloadFromStorage,
        orderNumber,
        order.patient.diagnostic.patientFiles
      );
    }
  }, [order]);

  useEffect(() => {
    if (
      order?.patient?.diagnostic?.patientFiles?.length &&
      orderFiles?.length &&
      orderFiles.some((file) => !file.id)
    ) {
      // Set patient file id for deletion
      const updatedOrderFiles = orderFiles.map((file) => {
        const id = order?.patient?.diagnostic?.patientFiles?.find((patientFile) =>
          areSameFiles(patientFile, file)
        )?.id;
        return { ...file, id };
      });
      if (updatedOrderFiles.every((file) => file.id)) {
        dispatch(ordersActions.setFiles(updatedOrderFiles));
      }
    }
    if (!file3dToDisplay) {
      displayFileInViewer(orderFiles, orderFiles[orderFiles.length - 1], dispatch);
    }
  }, [orderFiles]);

  useEffect(() => {
    // If an error occurred, reset files to their previous state
    if (labelFileError && filesBeforePatch) {
      dispatch(ordersActions.setFiles(filesBeforePatch));
    }

    // If it was a success, update previousState to this new one
    if (labelFileSuccess) {
      setFilesBeforePatch(orderFiles);
    }
  }, [labelFileError, labelFileSuccess]);

  const deleteFile = (fileToDelete: OrderFile): void => {
    deleteOrderFile({ orderNumber, file: fileToDelete });
    removeFile(dispatch, orderFiles, fileToDelete, file3dToDisplay);
  };

  const uploadFiles = async (newFilesToUpload: File[]): Promise<void> => {
    // update new files with loading true
    const newOrderFiles: OrderFile[] | undefined = await addFile(
      dispatch,
      orderFiles,
      newFilesToUpload,
      predictLabel
    );
    if (newOrderFiles?.length) {
      await uploadFilesToBackEnd(
        dispatch,
        uploadToOrder,
        uploadToStorage,
        newOrderFiles,
        orderNumber
      );
    }
  };

  const handleLabelChangeValue = (file: OrderFile, newValue: FileLabelEnum): void => {
    // Immediately display new label
    const updatedOrderFiles = orderFiles.map((orderFile) =>
      areSameFiles(orderFile, file) ? { ...orderFile, fileLabel: newValue } : orderFile
    );
    dispatch(ordersActions.setFiles(updatedOrderFiles));
    // Patch order
    if (newValue) {
      patchFileLabel({
        orderNumber: orderNumber,
        file: { ...file, fileLabel: newValue }
      });
    }
  };

  const fileActions = (file: OrderFile): [MenuEntry[]] => [
    [
      deleteFileAction(file, () => {
        deleteFile(file);
      })
    ]
  ];

  return (
    <>
      {(error ||
        uploadToOrderError ||
        orderError ||
        uploadToStorageError ||
        deleteFileError ||
        labelFileError ||
        attentionBoxError) && (
        <AttentionBox
          mode={ColorPropsEnum.DANGER}
          text={getMessageError(
            error ||
              uploadToOrderError ||
              orderError ||
              uploadToStorageError ||
              deleteFileError ||
              labelFileError
          )}
          className={styles['upload-file-form__attention-box']}
          onClose={() => {
            resetUploadToOrderError();
            resetDeleteFileError();
            resetUploadToStorageError();
            resetLabelFileError();
            setError(undefined);
          }}
        />
      )}
      {order && (
        <Box color={ColorPropsEnum.WHITE}>
          <form>
            <FileManagerContainer
              orientation={FileManagerOrientationProp.PORTRAIT}
              filesList={orderFiles}
              height="calc(100vh - 37rem)"
              isViewerLoading={isLoadingFiles}
              file3DToDisplay={file3dToDisplay}
              fileTextureToDisplay={fileTextureToDisplay}
              fileImageToDisplay={fileImageToDisplay}
              fileActions={fileActions}
              onUploadFileCallback={(newFiles: File[]) => uploadFiles(newFiles)}
              onLabelChangeCallback={(file: OrderFile, label: FileLabelEnum | string) =>
                handleLabelChangeValue(file, label as FileLabelEnum)
              }
              onClickFileCallback={(selectedFile: OrderFile) =>
                displayFileInViewer(orderFiles, selectedFile, dispatch)
              }
            />
            <div className="form__submit-button form__submit-button--right">
              <Button
                label={t('action.close', { ns: 'common' })}
                onClick={onSubmitCallback}
                isLoading={isLoadingFiles}
              />
            </div>
          </form>
        </Box>
      )}
    </>
  );
};

export default UploadForm;
